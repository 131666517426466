"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSubjectStatistics = getSubjectStatistics;
exports.getSubjectLevel = getSubjectLevel;
exports.getSubjectType = getSubjectType;
exports.getOrderQuestionList = getOrderQuestionList;
exports.getOrderStatistics = getOrderStatistics;
exports.getRandomQuestionList = getRandomQuestionList;
exports.getRandomStatistics = getRandomStatistics;
exports.getRandomResultLatest = getRandomResultLatest;
exports.cancelRandomExercise = cancelRandomExercise;
exports.commitRandomAnswer = commitRandomAnswer;
exports.addCollect = addCollect;
exports.removeCollect = removeCollect;
exports.commitAnswer = commitAnswer;
exports.getOrderAnswerCard = getOrderAnswerCard;
exports.getErrorQuestionList = getErrorQuestionList;
exports.removeErrorQuestion = removeErrorQuestion;
exports.getCollectQuestionList = getCollectQuestionList;
exports.getAnswerAnalysis = getAnswerAnalysis;
exports.getSchoolList = getSchoolList;
exports.getGradeList = getGradeList;
exports.getClassList = getClassList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取首页数据
function getSubjectStatistics() {
  return (0, _request.default)({
    url: '/getSubjectStatistics'
  });
} // 根据题目级别


function getSubjectLevel() {
  return (0, _request.default)({
    url: '/getSubjectLevel'
  });
} // 根据级别获取题型列表


function getSubjectType(params) {
  return (0, _request.default)({
    url: '/getSubjectType',
    params
  });
} // 获取顺序练习题目


function getOrderQuestionList(params) {
  return (0, _request.default)({
    url: '/getSubjectListByOrder',
    params
  });
} // 获取顺序练习统计


function getOrderStatistics(params) {
  return (0, _request.default)({
    url: '/getAnswerRecordByOrder',
    params
  });
} // 获取随机练习题目


function getRandomQuestionList(params) {
  return (0, _request.default)({
    url: '/getSubjectListByRandom',
    params
  });
} // 获取随机练习统计数据


function getRandomStatistics(params) {
  return (0, _request.default)({
    url: '/getRandomStatistics',
    params
  });
} // 获取最近一次随机答题结果


function getRandomResultLatest(params) {
  return (0, _request.default)({
    url: '/getAnswerSheetByRandom',
    params
  });
} // 取消随机练习


function cancelRandomExercise(params) {
  return (0, _request.default)({
    url: '/doRemoveRandomSubject',
    method: 'post',
    params
  });
} // 提交随机练习答题卡


function commitRandomAnswer(data) {
  return (0, _request.default)({
    url: '/doBatchSubjectRespond',
    method: 'post',
    data
  });
} // 添加收藏


function addCollect(params) {
  return (0, _request.default)({
    url: '/doAddCollect',
    method: 'post',
    params
  });
} // 移除收藏


function removeCollect(params) {
  return (0, _request.default)({
    url: '/doRemoveCollect',
    method: 'post',
    params
  });
} // 提交单个答案


function commitAnswer(data) {
  return (0, _request.default)({
    url: '/doSubjectRespond',
    method: 'post',
    data
  });
} // 顺序答题答题卡


function getOrderAnswerCard(params) {
  return (0, _request.default)({
    url: '/getAnswerSheetByOrder',
    params
  });
} // 获取错题集题目


function getErrorQuestionList(params) {
  return (0, _request.default)({
    url: '/getSubjectListByError',
    params
  });
} // 移除错题


function removeErrorQuestion(params) {
  return (0, _request.default)({
    url: '/doRemoveErrorSubject',
    method: 'post',
    params
  });
} // 获取收藏集题目


function getCollectQuestionList(params) {
  return (0, _request.default)({
    url: '/getSubjectListByCollect',
    params
  });
} // 获取答题分析数据


function getAnswerAnalysis(params) {
  return (0, _request.default)({
    url: '/getAnswerAnalysis',
    params
  });
} // 获取学校列表


function getSchoolList(params) {
  return (0, _request.default)({
    url: '/getSchoolList',
    params
  });
} // 获取年级列表


function getGradeList(params) {
  return (0, _request.default)({
    url: '/getGradeList',
    params
  });
} // 获取班级列表


function getClassList(params) {
  return (0, _request.default)({
    url: '/getClassList',
    params
  });
}