var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-card" },
    _vm._l(_vm.data, function(row, rowIndex) {
      return _c(
        "van-row",
        { key: rowIndex },
        _vm._l(row, function(col, colIndex) {
          return _c("van-col", { key: colIndex, attrs: { span: "12" } }, [
            _c("div", { staticClass: "label" }, [_vm._v(_vm._s(col.label))]),
            _c("div", { staticClass: "value" }, [_vm._v(_vm._s(col.value))])
          ])
        }),
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }