"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style/less");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/toast/style/less");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("vant/es/progress/style/less");

var _progress = _interopRequireDefault(require("vant/es/progress"));

var _vue = _interopRequireDefault(require("vue"));

var _Index = _interopRequireDefault(require("@/components/Question/Index"));

var _AnswerCard = _interopRequireDefault(require("@/components/AnswerCard"));

var _ShareDialog = _interopRequireDefault(require("@/components/ShareDialog"));

var _Empty = _interopRequireDefault(require("@/components/Empty"));

var _common = require("@/utils/common");

var _vuex = require("vuex");

var _constant = require("@/utils/constant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.use(_progress.default);

var _default = {
  name: 'Exercise',
  components: {
    Question: _Index.default,
    AnswerCard: _AnswerCard.default,
    ShareDialog: _ShareDialog.default,
    Empty: _Empty.default
  },

  data() {
    return {
      collected: require('assets/imgs/icons/collect_y.svg'),
      unCollect: require('assets/imgs/icons/collect_n.svg'),
      answers: [],
      nowQuestionIndex: 0,
      questionType: '单选题',
      showAnswerCard: false,
      // 是否显示答题卡
      solution: false,
      // 解析
      originSolution: false,
      // 原始解析
      timer: 0,
      // 计时
      intevalId: null,
      isEmpty: true,
      // 题目是否为空
      rightRate: 0,
      showRandomResult: false,
      // 显示随机结果答题卡
      timerStrChinese: '',
      // 作答时间
      resultTips: '',
      // 结果评语
      errorRange: [0, 0] // 错题下标区别，用于仅查看错题逻辑判断

    };
  },

  mounted() {
    if (!this.exerciseType) {
      // Toast('未选择练习模式')
      this.$router.push('/');
      return;
    } // 顺序答题


    if (this.isOrder && (!this.orderLevel || !this.orderQuestionType)) {
      (0, _toast.default)('未选择等级和题型');
      return;
    }

    document.title = this.answerCardTitle;

    if (!this.questionList || this.questionList.length === 0) {
      // 无题目
      return;
    } // 加载随机答题结果


    if (this.randomResult) {
      const {
        answerAccuracy,
        answerElapsedTime,
        answerElapsedTimeName
      } = this.randomResult;
      this.rightRate = (0, _common.toPercent)(answerAccuracy, false);
      this.timer = answerElapsedTime;
      this.timerStrChinese = answerElapsedTimeName;
      this.getResultTips(answerAccuracy);
      this.showRandomResult = true;
      this.setErrorIndexRange();
    }

    this.isEmpty = false;

    if (this.isOrder) {
      this.getOrderAnswerCardList();
    } else {
      this.setNowQuestion();
    } // 计时


    if (this.isRandom && !this.randomResult) {
      this.startTiming();
    }
  },

  computed: { ...(0, _vuex.mapState)({
      exerciseType: state => state.exercise.exerciseType,
      orderLevel: state => state.exercise.orderLevel,
      orderQuestionType: state => state.exercise.orderQuestionType,
      questionList: state => state.exercise.questionList,
      answerList: state => state.exercise.answerList,
      nowQuestion: state => state.exercise.nowQuestion,
      solutionList: state => state.exercise.solutionList,
      orderAnswerCard: state => state.exercise.orderAnswerCard,
      randomAnswerCard: state => state.exercise.randomAnswerCard,
      displayRandomResult: state => state.exercise.displayRandomResult,
      viewErrorQuestion: state => state.exercise.viewErrorQuestion,
      randomResult: state => state.exercise.randomResult,
      displayShare: state => state.exercise.displayShare,
      volumePlayer: state => state.exercise.volumePlayer
    }),
    ...(0, _vuex.mapGetters)(['isOrder', 'isRandom', 'isError', 'isCollect', 'answerCardTitle']),

    // 完成数量
    finishCount() {
      if (!this.orderAnswerCard) {
        return 0;
      }

      let count = 0;

      for (const item of this.orderAnswerCard) {
        if (item.answerStatus > 0) {
          count++;
        }
      }

      return count;
    },

    // 完成百分比
    progressPercent() {
      if (!this.questionList || this.questionList.length === 0) {
        return 0;
      }

      if (this.isOrder) {
        return this.questionList ? this.finishCount / this.questionList.length * 100 : 0;
      }

      if (this.isError || this.isCollect) {
        return this.solutionList ? Object.values(this.solutionList).filter(val => val !== undefined).length / this.questionList.length * 100 : 0;
      }

      const answerCount = Object.values(this.answerList).filter(item => item !== undefined && item.length > 0 && item.join('') !== '').length;
      return this.questionList && this.questionList.length > 0 && this.answerList ? answerCount / this.questionList.length * 100 : 0;
    },

    // 显示练习
    showExercise() {
      return !this.showAnswerCard && !this.showRandomResult && !this.showEmpty;
    },

    // 是否是选择题
    isChoice() {
      return this.nowQuestion ? _constant.questionComponents[this.nowQuestion.subjectType] === 'choice' : false;
    },

    // 提交按钮状态
    comfirmBtnDisabled() {
      if (!this.answerList || !this.nowQuestion) {
        return false;
      }

      const answers = this.answerList[this.nowQuestion.poetrySubjectId];

      if (answers && answers.length > 0) {
        return false;
      }

      return true;
    },

    // 提交按钮显示状态
    confirmBtnDisplay() {
      if (this.solution) {
        return false;
      }

      return true;
    },

    // 格式化计时
    timerStr() {
      return (0, _common.second2Time)(this.timer);
    },

    // 当前题号
    nowQuestionNum() {
      if (this.questionList && this.nowQuestionIndex >= this.questionList.length - 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.nowQuestionIndex = this.questionList.length - 1;
        return this.questionList.length;
      }

      return this.nowQuestionIndex + 1;
    },

    // 无题目
    showEmpty() {
      if (this.questionList && this.questionList.length === 0) {
        return true;
      }

      return false;
    },

    yourAnswer() {
      let answer = null;

      if (this.solutionList && this.solutionList[this.nowQuestion.poetrySubjectId]) {
        answer = this.solutionList[this.nowQuestion.poetrySubjectId];
      } else if (this.orderAnswerCard && this.orderAnswerCard[this.nowQuestionIndex] && this.orderAnswerCard[this.nowQuestionIndex].answerStatus > 0) {
        answer = this.orderAnswerCard[this.nowQuestionIndex];
      } else {
        return '';
      }

      const answers = [];
      answer.userAnswers.map(item => {
        answers.push(item.answer);
      });
      answers.sort();
      return answers.join('');
    }

  },
  methods: { ...(0, _vuex.mapActions)(['setAnswer', 'setCollectStatus', 'getOrderAnswerCard']),

    // 设置当前题目、答案和解析
    setNowQuestion() {
      this.$store.commit('SET_NOW_QUESTION', { ...this.questionList[this.nowQuestionIndex],
        questionIndex: this.nowQuestionIndex
      });
      this.setQuestionTypeTitle();
      this.setSolution();
    },

    // 设置答案和解析
    setSolution(solution = false) {
      if (!solution) {
        if (!this.nowQuestion) {
          this.solution = false;
          return;
        }

        solution = this.solutionList[this.nowQuestion.poetrySubjectId];
      }

      if (!solution) {
        if (this.isOrder) {
          solution = this.orderAnswerCard[this.nowQuestionIndex];
        }

        if (!solution || solution['answerStatus'] === 0 || solution['answerStatus'] === 2) {
          this.solution = false;
          this.originSolution = false;
          return;
        }
      }

      this.originSolution = solution;
      const slot = this.isChoice ? '' : '、';
      const answer = solution.answers.reduce((pre, cur) => {
        return pre + (pre === '' ? '' : slot) + cur.answer;
      }, '');
      this.solution = {
        parsing: solution.subjectExplam,
        // 解析
        answer: answer // 答案

      };
    },

    // 上一题
    prevQuestion() {
      if (this.nowQuestionIndex <= 0) {
        // Toast('已经是第一题啦！')
        return;
      }

      this.nowQuestionIndex--;

      if (this.viewErrorQuestion && this.randomAnswerCard[this.nowQuestionIndex].answerStatus === 1) {
        // 只看错题
        if (this.nowQuestionIndex > 0 && this.nowQuestionIndex > this.errorRange[0]) {
          this.prevQuestion();
        } else {
          this.nowQuestionIndex++;
        }

        return;
      }

      this.setNowQuestion();
      this.stopVolume();
    },

    // 下一题
    nextQuestion() {
      // 顺序练习、错题集、收藏集下一题会提交，并且有答案
      if ((this.isOrder || this.isError || this.isCollect) && !this.solution && this.answerList && this.answerList[this.nowQuestion.poetrySubjectId] && this.answerList[this.nowQuestion.poetrySubjectId].join('').trim() !== '') {
        // 提交答案
        this.confirm();
        return;
      }

      if (this.nowQuestionIndex >= this.questionList.length - 1) {
        // 随机练习最后一题，提交
        if (this.isRandom && !this.displayRandomResult) {
          let doneCount = 0;
          this.questionList.map((item, index) => {
            // 是否已答，每个答案都填完了
            const done = this.answerList && this.answerList[item.poetrySubjectId] && this.answerList[item.poetrySubjectId].join('') !== '';

            if (done) {
              doneCount++;
            }
          });
          this.commitAnswerCard(doneCount);
          return;
        }

        if (!this.solution) {
          (0, _toast.default)('已经是最后一题啦！');
        }

        return;
      }

      this.nowQuestionIndex++;

      if (this.viewErrorQuestion && this.randomAnswerCard[this.nowQuestionIndex].answerStatus === 1) {
        // 只看错题
        if (this.nowQuestionIndex < this.questionList.length - 1 && this.nowQuestionIndex < this.errorRange[1]) {
          this.nextQuestion();
        } else {
          this.nowQuestionIndex--;
        }

        return;
      }

      this.setNowQuestion();
      this.stopVolume();
    },

    // 提交答案
    confirm() {
      this.stopVolume();

      if (this.isRandom) {
        // 随机练习不提交
        if (this.nowQuestionIndex === this.questionList.length - 1) {
          this.showAnswerCard = true;
          return;
        }

        this.nextQuestion();
      } else {
        if (this.solution === false) {
          this.setAnswer().then(data => {
            this.originSolution = data;
            this.setSolution(data);
          }).catch(res => {
            // 需要填写学生信息
            if (res.code === _constant.codes.NEED_FILL_INFO) {
              (0, _common.showConfirm)(_dialog.default, {
                message: '请补充您的个人信息后，再继续答题。',
                cancelButtonText: '关闭',
                confirmButtonText: '去填写'
              }, () => {
                // confirm
                this.$router.replace('/user');
              }, () => {// cancel
              });
            } else {
              (0, _toast.default)(res.message);
            }
          });
        } else {
          (0, _toast.default)('你已经做过这题啦！');
        }
      }
    },

    // 切换答题卡状态
    changeAnswerCard() {
      if (this.displayRandomResult) {
        // 随机答题结果选择答题卡
        this.showRandomResult = !this.showRandomResult;
      } else {
        this.showAnswerCard = !this.showAnswerCard;
      }

      this.stopVolume();
    },

    // 选择题目
    selectQuestion(index) {
      if (this.displayRandomResult) {
        // 随机练习查看解析
        this.showRandomResult = false;

        if (this.viewErrorQuestion && this.randomAnswerCard[index].answerStatus === 1) {
          this.$store.commit('SET_VIEW_ERROR_QUESTION', false);
        }
      } else {
        // 非随机练习点击题卡恢复题目形式
        if (this.solutionList[this.questionList[index]['poetrySubjectId']]) {
          const question = this.questionList[index]; // 宫格题答错还需要删除宫格答案

          if (this.isError || this.isCollect || this.solutionList[question.poetrySubjectId].answerStatus === 2) {
            this.$store.commit('SET_GRID_ANSWER', {
              id: question.poetrySubjectId,
              answer: undefined
            });
          }

          this.$store.commit('SET_SOLUTION', {
            poetrySubjectId: question.poetrySubjectId,
            data: undefined
          });
          this.$store.commit('SET_ANSWER', {
            poetrySubjectId: question.poetrySubjectId,
            answer: undefined
          });
          this.originSolution = false;
        }

        this.changeAnswerCard();
      }

      this.nowQuestionIndex = index;
      this.setNowQuestion();
    },

    // 设置收藏状态
    setQuestionCollectStatus() {
      this.setCollectStatus().then(res => {
        _toast.default.success(res);

        this.setSolution();
      }).catch(error => {
        (0, _toast.default)(error);
      });
    },

    // 获取答题卡信息
    getOrderAnswerCardList() {
      this.getOrderAnswerCard({
        subjectLevel: this.orderLevel,
        subjectType: this.orderQuestionType
      }).then(data => {
        // 顺序练习定位到指定题目
        for (let i = 0; i < data.length; i++) {
          if (data[i].answerStatus === 0 || data[i].answerStatus === 2) {
            // 未答或答错
            this.nowQuestionIndex = i;
            break;
          }
        }

        this.setNowQuestion();
      }).catch(err => {
        (0, _toast.default)(err);
        setTimeout(() => {
          this.$router.push('/');
        }, 2000);
      });
    },

    // 退出询问弹窗
    beforeExist(next, message, cancelText, confirmText) {
      (0, _common.showConfirm)(_dialog.default, {
        message: message || '确定退出本次答题吗？',
        cancelButtonText: cancelText || '确认返回',
        confirmButtonText: confirmText || '继续答题'
      }, () => {
        // confirm
        next(false);
      }, () => {
        // cancel
        next();
      });
    },

    // 计时
    startTiming() {
      this.intevalId = setInterval(() => {
        this.timer++;
      }, 1000);
    },

    // 设置题型
    setQuestionTypeTitle() {
      this.questionType = this.nowQuestion.subjectTypeName;
    },

    // 设置随机答题结果错题区间
    setErrorIndexRange() {
      let start = -1;
      let end = -1;
      this.randomAnswerCard.map((item, index) => {
        if (item.answerStatus === 2) {
          if (start === -1) {
            start = index;
          } else {
            end = index;
          }
        }
      });
      this.errorRange = [start, end];
    },

    // 评语
    getResultTips(resultRate) {
      switch (resultRate) {
        case 10:
        case 9:
        case 8:
          this.resultTips = '太棒啦！';
          break;

        case 7:
        case 6:
          this.resultTips = '还行啦！';
          break;

        default:
          this.resultTips = '一般般！';
          break;
      }
    },

    // 提交随机练习答案请求
    commitRandomExercise() {
      this.$store.dispatch('commitRandomAnswerCard', {
        time: this.timer
      }).then(data => {
        clearInterval(this.intevalId);
        this.setErrorIndexRange();
        this.showAnswerCard = false;
        this.showRandomResult = true; // 统计时间

        this.timerStrChinese = (0, _common.second2Time)(this.timer, 'chinese'); // 计算正确率

        let rightCount = 0;
        data.map(item => {
          if (item.answerStatus === 1) {
            rightCount++;
          }
        });
        this.rightRate = (0, _common.toPercent)(rightCount / data.length, false);
        this.getResultTips((rightCount / data.length * 10).toFixed(0)); // 设置标题

        document.title = '答题结果';
      });
    },

    // 随机练习提交答题卡
    commitAnswerCard(doneCount) {
      if (!this.isRandom) {
        return;
      }

      const undo = this.questionList.length - doneCount;
      let message = '确定要提交吗？';

      if (undo > 0) {
        message = `还有${undo}题未答，确定提交？`;
      }

      (0, _common.showConfirm)(_dialog.default, {
        message,
        cancelButtonText: '继续答题',
        confirmButtonText: '确定提交'
      }, () => {
        // confirm
        this.commitRandomExercise();
      }, () => {// cancel
      });
    },

    // 停止播放
    stopVolume() {
      if (this.volumePlayer) {
        this.volumePlayer.pause();
        this.$store.commit('SET_VOLUME_PLAYER', null);
      }
    }

  },

  beforeDestroy() {
    // 清空作答和解析
    this.$store.commit('CLEAR_ANSWER'); // 清空计时器id

    if (this.intevalId) {
      clearInterval(this.intevalId);
    } // 停止播放音频


    this.stopVolume();
  },

  // TODO: 刷新暂时没有很好的解决方案
  // beforeRouteEnter(to, from, next) {
  //   console.log('to', to)
  //   console.log('from', from)
  //   next(vm => {
  //     if (from.name !== 'OrderIndex') {
  //       console.log(vm.exerciseType)
  //       if (vm.exerciseType) {
  //         // 刷新页面
  //         vm.beforeExist(next)
  //       } else {
  //         // 直接进入
  //         vm.$router.push('/')
  //       }
  //     }
  //   })
  // },
  // 监听页面返回
  beforeRouteLeave(to, from, next) {
    if (to.name === 'Home' || to.name === 'User' || this.isEmpty || this.displayRandomResult) {
      // 跳转首页/用户信息页面，or题目为空不显示
      next();
      return;
    }

    this.beforeExist(next);
  }

};
exports.default = _default;