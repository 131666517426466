"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _getters = _interopRequireDefault(require("./getters"));

var _app = _interopRequireDefault(require("./modules/app"));

var _exercise = _interopRequireDefault(require("./modules/exercise"));

var _user = _interopRequireDefault(require("./modules/user"));

var _mini = _interopRequireDefault(require("./modules/mini"));

_vue.default.use(_vuex.default);

const store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    user: _user.default,
    exercise: _exercise.default,
    mini: _mini.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;