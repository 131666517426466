var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question" },
    [
      _vm.article
        ? _c(
            "van-row",
            {
              staticClass: "view-article",
              attrs: { type: "flex", justify: "space-between" }
            },
            [
              _c(
                "van-col",
                { staticClass: "article-title", attrs: { span: "17" } },
                [_vm._v(_vm._s(_vm.article.title))]
              ),
              _c(
                "van-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "btn",
                      attrs: {
                        round: "",
                        block: "",
                        size: "small",
                        type: "primary"
                      },
                      on: { click: _vm.viewArticle }
                    },
                    [_vm._v("查看文章 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("topic", {
        staticClass: "topic",
        attrs: {
          title: _vm.question.content,
          volume: _vm.question.voiceUrl,
          image: _vm.question.imageUrl
        }
      }),
      _c(_vm.questionType, {
        tag: "component",
        attrs: { question: _vm.question, solution: _vm.solution }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }