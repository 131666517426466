"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/progress/style/less");

var _progress = _interopRequireDefault(require("vant/es/progress"));

var _vue = _interopRequireDefault(require("vue"));

var _subject = require("@/api/subject");

var _echarts = _interopRequireDefault(require("@/utils/echarts"));

var _common = require("@/utils/common");

// import Radar from '@/components/Charts/Radar'
_vue.default.use(_progress.default);

var _default = {
  name: 'Analysis',

  data() {
    return {
      defaultOptions: {
        title: {
          text: ''
        },
        legend: {
          data: [],
          itemHeight: 6,
          itemGap: 30,
          bottom: 0
        },
        radar: {
          indicator: [],
          splitNumber: 4,
          splitArea: {
            areaStyle: {
              color: ['rgba(255,255,255)']
            }
          },
          name: {
            textStyle: {
              fontSize: 8,
              color: '#8e8e8e'
            }
          }
        },
        series: [],
        color: ['#5B8FF9', '#5AD8A6']
      },
      questionOptions: null,
      // 题型覆盖雷达
      finishOptions: null,
      // 题型答题次数雷达
      data: {
        subjectCount: 0,
        // 题目总数
        asAnswerAccuracy: 0,
        // 正确率
        asAnswerRate: 0,
        // 答题率
        asCorrectNum: 0,
        // 正确数
        asErrorNum: 0,
        // 错误数
        asNotNum: 0,
        // 未答数
        atNum: 0,
        // 答题总数
        atAnswerAccuracy: 0,
        // 答题次数正确率
        atCorrectNum: 0,
        // 答题正确次数
        atErrorNum: 0 // 答题错误次数

      }
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      (0, _subject.getAnswerAnalysis)().then(res => {
        if (res.code !== 200) {
          this.$toast('获取数据失败');
          return;
        } // 格式化百分比


        res.data.asAnswerRate = (0, _common.toPercent)(res.data.asAnswerRate, false);
        res.data.asAnswerAccuracy = (0, _common.toPercent)(res.data.asAnswerAccuracy, false);
        res.data.atAnswerAccuracy = (0, _common.toPercent)(res.data.atAnswerAccuracy, false);
        this.data = res.data; // 题型正确率雷达图

        const questionOptionIndicator = [];
        const questionCountSeries = [];
        const questionRightSeries = [];
        res.data.asTypeAnalysis.map(item => {
          questionOptionIndicator.push({
            name: item.subjectTypeName,
            max: item.subjectCount
          });
          questionCountSeries.push(item.asHaveNum);
          questionRightSeries.push(item.asCorrectNum);
        });
        const questionOptions = Object.assign({}, this.defaultOptions);
        questionOptions.legend.data = ['题目数', '正确率'];
        questionOptions.radar.indicator = questionOptionIndicator;
        console.log('questionOptionIndicator', questionOptionIndicator);
        console.log('questionCountSeries', questionCountSeries);
        console.log('questionRightSeries', questionRightSeries);
        questionOptions.series = [{
          name: '',
          type: 'radar',
          data: [{
            value: questionCountSeries,
            name: '题目数',
            symbol: 'none',
            areaStyle: {
              color: 'rgba(91, 143, 249, .25)'
            }
          }, {
            value: questionRightSeries,
            name: '正确率',
            symbol: 'none',
            areaStyle: {
              color: 'rgba(90, 216, 166, .25)'
            }
          }]
        }];
        this.questionOptions = questionOptions;

        const questionChart = _echarts.default.init(document.getElementById('radar-1'));

        questionChart.setOption(this.questionOptions); // 答题次数正确率

        const finishOptionIndicator = []; // const finishCountSeries = []

        const finishRightSeries = [];
        res.data.atTypeAnalysis.map(item => {
          finishOptionIndicator.push({
            name: item.subjectTypeName,
            max: item.asHaveNum === 0 ? 1 : item.asHaveNum
          }); // finishCountSeries.push(item.asCorrectNum)

          finishRightSeries.push(item.asCorrectNum);
        });
        const finishOptions = Object.assign({}, this.defaultOptions);
        finishOptions.legend.data = ['正确率'];
        finishOptions.radar.indicator = finishOptionIndicator;
        finishOptions.series = [{
          name: '',
          type: 'radar',
          data: [// {
          //   value: finishCountSeries,
          //   name: '答题次数',
          //   symbol: 'none',
          //   areaStyle: {
          //     color: 'rgba(91, 143, 249, .25)'
          //   }
          // },
          {
            value: finishRightSeries,
            name: '正确率',
            symbol: 'none',
            areaStyle: {
              color: 'rgba(90, 216, 166, .25)'
            }
          }]
        }];
        this.finishOptions = finishOptions;

        const finishChart = _echarts.default.init(document.getElementById('radar-2'));

        finishChart.setOption(this.finishOptions);
      });
    }

  }
};
exports.default = _default;