"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DataCard = _interopRequireDefault(require("@/components/DataCard"));

var _subject = require("@/api/subject");

var _Empty = _interopRequireDefault(require("@/components/Empty"));

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    DataCard: _DataCard.default,
    Empty: _Empty.default
  },
  name: 'Record',

  data() {
    return {
      data: null
    };
  },

  methods: {
    getData(level, type) {
      (0, _subject.getOrderStatistics)({
        subjectLevel: level,
        subjectType: type
      }).then(res => {
        if (res.code !== 200) {
          this.$toast.fail(res.message);
          this.data = null;
          return;
        }

        const {
          answerPercent,
          haveAnswerCount,
          notAnswerCount,
          answerAccuracy,
          allHaveAnswerCount,
          allAnswerAccuracy
        } = res.data;
        const data = [[{
          label: '答题进度',
          value: (0, _common.toPercent)(answerPercent)
        }], [{
          label: '已答题数',
          value: haveAnswerCount
        }, {
          label: '未答题数',
          value: notAnswerCount
        }], [{
          label: '已答题正确率',
          value: (0, _common.toPercent)(answerAccuracy)
        }], [{
          label: '答题次数',
          value: allHaveAnswerCount
        }, {
          label: '答题次数正确率',
          value: (0, _common.toPercent)(allAnswerAccuracy)
        }]];
        this.data = data;
      });
    }

  },

  created() {
    const level = this.$route.query.level || null;
    const type = this.$route.query.type || null;

    if (!level || !type) {
      this.$router.push('order');
      return;
    }

    this.getData(level, type);
  }

};
exports.default = _default;