"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style/less");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

var _DataCard = _interopRequireDefault(require("@/components/DataCard"));

var _ShareDialog = _interopRequireDefault(require("@/components/ShareDialog"));

var _Empty = _interopRequireDefault(require("@/components/Empty"));

var _subject = require("@/api/subject");

var _common = require("@/utils/common");

var _constant = require("@/utils/constant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Stochastic',
  components: {
    DataCard: _DataCard.default,
    ShareDialog: _ShareDialog.default,
    Empty: _Empty.default
  },
  data: () => {
    return {
      preData: null,
      dataDetail: null
    };
  },

  created() {
    this.getData();
  },

  methods: {
    start() {
      this.$store.dispatch('startRandomExericse').then(() => {
        this.$router.push('/exercise');
      }).catch(res => {
        // 需要填写学生信息
        if (res.code === _constant.codes.NEED_FILL_INFO) {
          (0, _common.showConfirm)(_dialog.default, {
            message: '请补充您的个人信息后，再继续答题。',
            cancelButtonText: '关闭',
            confirmButtonText: '去填写'
          }, () => {
            // confirm
            this.$router.replace('/user');
          }, () => {// cancel
          });
        } else if (res.data.length <= 0) {
          this.$toast('加载题库失败');
        } else {
          this.$toast(res.message);
        }
      });
    },

    getData() {
      (0, _subject.getRandomStatistics)().then(res => {
        if (res.code !== 200) {
          this.preData = null;
          this.$toast.fail(res.message);
        }

        const {
          respondCount,
          respondAccuracyAvg,
          respondElapsedTimeAvgName,
          latelyAccuracy,
          latelyRespondTime,
          latelyRespondElapsedTimeName
        } = res.data;

        if (respondCount <= 0) {
          return;
        }

        this.preData = [[{
          label: '练习次数',
          value: respondCount
        }, {
          label: '平均正确率',
          value: (0, _common.toPercent)(respondAccuracyAvg)
        }], [{
          label: '平均答题时间',
          value: respondElapsedTimeAvgName
        }]];
        this.dataDetail = {
          label: '最近一次答题情况',
          value: {
            latelyRespondTime,
            latelyRespondElapsedTimeName,
            latelyAccuracy: (0, _common.toPercent)(latelyAccuracy)
          }
        };
      });
    },

    viewPreData() {
      this.$store.dispatch('getRandomResultLatest').then(data => {
        this.$router.push('/exercise');
      }).catch(err => {
        this.$toast(err);
      });
    }

  }
};
exports.default = _default;