"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'NavItem',
  props: {
    info: {
      type: Object,
      require: true
    }
  },
  methods: {
    nav() {
      if (this.info.clickCallback) {
        this.info.clickCallback();
        return;
      }

      if (!this.info.url) {
        return;
      }

      this.$router.push(this.info.url);
    }

  }
};
exports.default = _default;