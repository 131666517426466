var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nav-item-wrapper" }, [
    _c(
      "div",
      { staticClass: "nav-item", class: _vm.info.type, on: { click: _vm.nav } },
      [
        _c("img", { staticClass: "icon", attrs: { src: _vm.info.icon } }),
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.info.title))]),
        _c("p", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.info.desc))])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }