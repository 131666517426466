"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      info: [{
        title: '功能说明',
        questions: [{
          title: '常规练习',
          answer: '用于选择专类题型进行练习。题型包括单选题、多选题、填空题、对句题、九宫格题、十二宫格题和二十宫格题，学生可以选择自己所处的年级进行练习。'
        }, {
          title: '综合练习',
          answer: '用于各类题型的综合练习。自动匹配学生当前年级的水平，随机出题，共10道题，让学生进行练习。'
        }, {
          title: '错题集',
          answer: '用于查漏补缺。学生完成答题后，系统自动收集错题并提供错题解析。'
        }, {
          title: '题目收藏',
          answer: '用于记录收藏题目，方便回顾重点题目与加深记忆。'
        }, {
          title: '答题分析',
          answer: '用于统计学生的答题进度和情况，包括各类题型的正确率，让学生进一步加强学习和积累。'
        }]
      } // {
      //   title: '题型说明',
      //   questions: [
      //     {
      //       title: '单选题',
      //       answer: '从选项中选择仅有一个正确的选项。'
      //     },
      //     {
      //       title: '多选题',
      //       answer: '从选项中选择两个及以上正确的选项。'
      //     },
      //     {
      //       title: '填空题',
      //       answer: '在输入框中填入文字，使诗句完整。'
      //     },
      //     {
      //       title: '对句题',
      //       answer: '在输入框中填入诗句，完成诗句对句。'
      //     },
      //     {
      //       title: '九宫格',
      //       answer: '从九个字中识别诗句，填入输入框。'
      //     },
      //     {
      //       title: '十二宫格',
      //       answer: '从十二个字中识别诗句，填入输入框。'
      //     },
      //     {
      //       title: '二十宫格',
      //       answer: '从二十个字中识别诗句，填入输入框。'
      //     }
      //   ]
      // }
      ]
    };
  }

};
exports.default = _default;