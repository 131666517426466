"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style/less");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

var _weixinJsSdk = _interopRequireDefault(require("weixin-js-sdk"));

var _vuex = require("vuex");

var _common = require("@/utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AnswerCard',
  props: {
    close: {
      type: Function,
      require: true
    },
    select: {
      type: Function,
      require: true
    },
    commitAnswer: {
      type: Function
    },
    time: {
      type: String
    },
    undoCount: {
      type: Number
    },
    detectInfo: {
      type: Object
    },
    type: {
      type: String
    }
  },

  data() {
    return {
      doneCount: 0
    };
  },

  computed: { ...(0, _vuex.mapState)({
      miniResult: state => state.exercise.miniResult,
      exerciseType: state => state.exercise.exerciseType,
      questionList: state => state.exercise.questionList,
      answerList: state => state.exercise.answerList,
      solutionList: state => state.exercise.solutionList,
      displayRandomResult: state => state.exercise.displayRandomResult,
      randomAnswerCard: state => state.exercise.randomAnswerCard,
      miniPath: state => state.exercise.miniPath,
      isMiniResult: state => state.exercise.isMiniResult,
      isMini: state => state.exercise.isMini,
      orderAnswerCard: function (state) {
        const answerCard = [];

        if (this.isOrder) {
          state.exercise.questionList.map((item, index) => {
            let answerStatus = 0;

            if (state.exercise.orderAnswerCard && state.exercise.orderAnswerCard[index]) {
              answerStatus = state.exercise.orderAnswerCard[index].answerStatus;
            }

            answerCard[index] = {
              answerStatus
            };
          });
          return answerCard;
        } // 错题集和收藏集


        if (this.isError || this.isCollect) {
          state.exercise.questionList.map((item, index) => {
            // 只有提交了才标注已答
            const done = state.exercise.solutionList && state.exercise.solutionList[item.poetrySubjectId];
            answerCard[index] = {
              done
            };
          });
          return answerCard;
        }

        let doneCount = 0;
        state.exercise.questionList.map((item, index) => {
          // 是否已答，每个答案都填完了
          const done = state.exercise.answerList && state.exercise.answerList[item.poetrySubjectId] && state.exercise.answerList[item.poetrySubjectId].join('') !== '';

          if (done) {
            doneCount++;
          }

          answerCard[index] = {
            done
          };
        });
        this.doneCount = doneCount;
        return answerCard;
      }
    }),
    ...(0, _vuex.mapGetters)(['isOrder', 'isRandom', 'isError', 'isCollect']),

    // 是否是随机练习查看结果
    isResult() {
      return this.isRandom && this.displayRandomResult;
    },

    // 已答数量
    solutionCount() {
      return Object.keys(this.solutionList).length;
    },

    // 答对数量
    rightCount() {
      const list = this.isResult ? this.randomAnswerCard : this.orderAnswerCard;

      if (!list) {
        return 0;
      }

      let count = 0;

      for (const item of list) {
        if (item.answerStatus === 1) {
          count++;
        }
      }

      return count;
    },

    // 答错数量
    errorCount() {
      return this.questionList.length - this.rightCount - this.undoCount;
    },

    // 题卡
    numberList() {
      const list = [];

      for (const question of this.questionList) {
        const solution = this.solutionList[question.poetrySubjectId];
        list.push({
          status: solution ? solution.answerStatus === 1 ? 'success' : 'error' : ''
        });
      }

      return list;
    }

  },
  methods: {
    viewFirstQuestion() {
      this.$store.commit('SET_VIEW_ERROR_QUESTION', false);
      this.select(0);
    },

    viewErrorQuestion() {
      this.$store.commit('SET_VIEW_ERROR_QUESTION', true);
      let firstErrorIndex = 0;

      for (let index = 0; index < this.randomAnswerCard.length; index++) {
        if (this.randomAnswerCard[index].answerStatus === 2) {
          firstErrorIndex = index;
          break;
        }
      }

      this.select(firstErrorIndex);
    },

    goHome() {
      (0, _common.showConfirm)(_dialog.default, {
        message: '确定退出本次答题吗？',
        cancelButtonText: '确认返回',
        confirmButtonText: '继续答题'
      }, () => {// confirm
      }, () => {// cancel
      });
    },

    // 返回小程序
    goMini() {
      // 退出到小程序页面
      _weixinJsSdk.default.miniProgram.navigateBack();

      return;
    },

    // 再答一次
    again() {
      if (this.detectInfo.detectedNum >= this.detectInfo.limitDetectNum) {
        // 没有次数了
        this.$toast('此书答题次数已用完');
        return;
      }

      _weixinJsSdk.default.miniProgram.navigateBack();

      _weixinJsSdk.default.miniProgram.postMessage({
        data: {
          doAgain: true
        }
      });
    },

    // 跳转到计划
    goPlan() {
      _weixinJsSdk.default.miniProgram.navigateTo({
        // todo id
        url: `/planPages/buy/index?id=1859704342130005`
      });
    }

  }
};
exports.default = _default;