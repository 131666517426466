var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "analysis-container" },
    [
      _c(
        "van-row",
        [
          _c("p", { staticClass: "title" }, [_vm._v("答题进度")]),
          _c(
            "van-row",
            { staticClass: "section" },
            [
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(" 题目覆盖情况"),
                _c("span", { staticClass: "percentText" }, [
                  _vm._v(_vm._s(_vm.data.asAnswerRate) + "%")
                ])
              ]),
              _c("van-progress", {
                attrs: {
                  percentage: _vm.data.asAnswerRate,
                  "show-pivot": false
                }
              }),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "(共" +
                    _vm._s(_vm.data.subjectCount) +
                    "题，已答" +
                    _vm._s(_vm.data.subjectCount - _vm.data.asNotNum) +
                    "题)"
                )
              ])
            ],
            1
          ),
          _c(
            "van-row",
            { staticClass: "section" },
            [
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(" 题目正确率"),
                _c("span", { staticClass: "percentText" }, [
                  _vm._v(_vm._s(_vm.data.asAnswerAccuracy) + "%")
                ])
              ]),
              _c("van-progress", {
                attrs: {
                  percentage: _vm.data.asAnswerAccuracy,
                  "show-pivot": false
                }
              }),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "(已答" +
                    _vm._s(_vm.data.subjectCount - _vm.data.asNotNum) +
                    "题，正确" +
                    _vm._s(_vm.data.asCorrectNum) +
                    "题)"
                )
              ])
            ],
            1
          ),
          _c("van-row", { staticClass: "section" }, [
            _c("p", { staticClass: "subtitle" }, [_vm._v("各题型覆盖情况")]),
            _c("div", { staticClass: "radar", attrs: { id: "radar-1" } })
          ])
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "section" },
        [
          _c("p", { staticClass: "title" }, [_vm._v("答题情况")]),
          _c("van-row", [
            _c("p", { staticClass: "subtitle" }, [_vm._v("答题次数")]),
            _c("p", { staticClass: "bigNumber" }, [
              _vm._v(_vm._s(_vm.data.atNum))
            ])
          ]),
          _c(
            "van-row",
            { staticClass: "section" },
            [
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(" 答题次数正确率"),
                _c("span", { staticClass: "percentText" }, [
                  _vm._v(_vm._s(_vm.data.atAnswerAccuracy) + "%")
                ])
              ]),
              _c("van-progress", {
                attrs: {
                  percentage: _vm.data.atAnswerAccuracy,
                  "show-pivot": false
                }
              }),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "(答题" +
                    _vm._s(_vm.data.atNum) +
                    "次，正确" +
                    _vm._s(_vm.data.atCorrectNum) +
                    "次，错误" +
                    _vm._s(_vm.data.atErrorNum) +
                    "次)"
                )
              ])
            ],
            1
          ),
          _c("van-row", { staticClass: "section" }, [
            _c("p", { staticClass: "subtitle" }, [_vm._v("各题型答题正确率")]),
            _c("div", { staticClass: "radar", attrs: { id: "radar-2" } })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }