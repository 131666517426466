var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-wrapper" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("van-row", [
          _c("p", { staticClass: "info" }, [
            _vm._v("需关注公众号后，才能使用此功能，可以通过以下方式进行关注：")
          ])
        ]),
        _c(
          "van-row",
          { staticClass: "card" },
          [
            _c("van-col", { attrs: { span: "6" } }, [
              _c("div", { staticClass: "number-label" }, [_vm._v("1")])
            ]),
            _c("van-col", { attrs: { span: "24" } }, [
              _c("div", { staticClass: "subscribe-info" }, [
                _vm._v("通过微信顶部搜索“云上书院”进行关注；")
              ])
            ])
          ],
          1
        ),
        _c(
          "van-row",
          { staticClass: "card" },
          [
            _c("div", { staticClass: "number-label" }, [_vm._v("2")]),
            _c("van-col", { attrs: { span: "24" } }, [
              _c("div", { staticClass: "subscribe-info" }, [
                _c("p", [_vm._v("通过二维码方式进行关注：")]),
                _c("p", [_vm._v("（1）长按保存下方二维码到手机相册")]),
                _c("p", [
                  _vm._v("（2）然后打开微信扫一扫，点击扫一扫页面上的相册")
                ]),
                _c("p", [_vm._v("（3）选择刚才保存的二维码识别后关注")])
              ]),
              _c("div", { staticClass: "qrcode" }, [
                _c("img", { attrs: { src: _vm.qrcode, alt: "关注二维码" } })
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-img" }, [
      _c("img", {
        attrs: { src: require("assets/imgs/subscribe_header.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }