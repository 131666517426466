"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

require("vant/es/dialog/style/less");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/field/style/less");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/form/style/less");

var _form = _interopRequireDefault(require("vant/es/form"));

require("vant/es/divider/style/less");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/icon/style/less");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/col/style/less");

var _col = _interopRequireDefault(require("vant/es/col"));

require("vant/es/row/style/less");

var _row = _interopRequireDefault(require("vant/es/row"));

require("vant/es/tabbar/style/less");

var _tabbar = _interopRequireDefault(require("vant/es/tabbar"));

require("vant/es/tabbar-item/style/less");

var _tabbarItem = _interopRequireDefault(require("vant/es/tabbar-item"));

require("vant/es/list/style/less");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/cell/style/less");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/button/style/less");

var _button = _interopRequireDefault(require("vant/es/button"));

var _vue = _interopRequireDefault(require("vue"));

// 按需全局引入 vant组件
_vue.default.use(_button.default);

_vue.default.use(_cell.default);

_vue.default.use(_list.default);

_vue.default.use(_tabbar.default).use(_tabbarItem.default);

_vue.default.use(_row.default);

_vue.default.use(_col.default);

_vue.default.use(_icon.default);

_vue.default.use(_divider.default);

_vue.default.use(_form.default);

_vue.default.use(_field.default);

_vue.default.use(_dialog.default);