import { render, staticRenderFns } from "./Choice.vue?vue&type=template&id=e894b720&scoped=true&"
import script from "./Choice.vue?vue&type=script&lang=js&"
export * from "./Choice.vue?vue&type=script&lang=js&"
import style0 from "./Choice.vue?vue&type=style&index=0&id=e894b720&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e894b720",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e894b720')) {
      api.createRecord('e894b720', component.options)
    } else {
      api.reload('e894b720', component.options)
    }
    module.hot.accept("./Choice.vue?vue&type=template&id=e894b720&scoped=true&", function () {
      api.rerender('e894b720', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QuestionType/Choice.vue"
export default component.exports