var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-question", class: _vm.gridClass }, [
    _c(
      "div",
      { ref: "grid" },
      [
        _c(
          "van-row",
          { attrs: { type: "flex", justify: "space-around" } },
          _vm._l(_vm.question.options, function(item, index) {
            return _c(
              "van-col",
              {
                key: index,
                staticClass: "grid-item-wrapper",
                attrs: { span: _vm.span }
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      "grid-item",
                      {
                        active: _vm.textIndexs.indexOf(index) > -1
                      }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.clickText(index)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.subjectOption) + " ")]
                )
              ]
            )
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "answer-list" },
      _vm._l(_vm.question.answerCount, function(row) {
        return _c(
          "div",
          {
            key: row,
            class: [
              "answer-item",
              [
                !_vm.solution
                  ? ""
                  : _vm.solution.answerStatus === 1 ||
                    (_vm.solution.userAnswers &&
                      _vm.solution.userAnswers[row - 1] &&
                      _vm.solution.answers
                        .map(function(item) {
                          return item.answer
                        })
                        .indexOf(_vm.solution.userAnswers[row - 1].answer) > -1)
                  ? "success"
                  : "error"
              ]
            ]
          },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                _vm._s(
                  _vm.question.answerCount > 1
                    ? "请输入第" + _vm.nums[row - 1] + "句诗"
                    : "请输入诗句"
                )
              )
            ]),
            _vm._l(_vm.question.answerLengths[row - 1], function(col) {
              return _c("div", { key: col, staticClass: "answer-grid" }, [
                _c(
                  "div",
                  {
                    class: [
                      "answe-grid-content",
                      {
                        active:
                          !_vm.solution &&
                          _vm.nowRow === row - 1 &&
                          _vm.nowCol === col - 1
                      }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.clickAnswer(row - 1, col - 1)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.solution && _vm.answers[row - 1]
                            ? _vm.answers[row - 1][col - 1]
                            : _vm.answers[row - 1 + "-" + (col - 1)] > -1
                            ? _vm.question.options[
                                _vm.answers[row - 1 + "-" + (col - 1)]
                              ].subjectOption
                            : ""
                        ) +
                        " "
                    )
                  ]
                )
              ])
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }