var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c(
        "div",
        { staticClass: "share-dialog" },
        [
          _c("van-row", { staticClass: "arrow" }, [
            _c("img", {
              attrs: { src: require("assets/imgs/arrow.png"), alt: "" }
            })
          ]),
          _c(
            "van-row",
            {
              staticClass: "share-card",
              attrs: { type: "flex", justify: "center" }
            },
            [
              _c("van-row", { staticClass: "header" }, [
                _c("img", {
                  attrs: {
                    src: require("assets/imgs/subscribe_header.png"),
                    alt: ""
                  }
                })
              ]),
              _c(
                "van-row",
                {
                  staticClass: "share-content",
                  attrs: { type: "flex", justify: "center" }
                },
                [
                  _c("p", [
                    _vm._v(
                      "今天题目练习次数已达上限，请点击右上角选择分享朋友圈，分享成功后即可继续练习。"
                    )
                  ]),
                  _c(
                    "van-button",
                    {
                      staticClass: "close-btn",
                      attrs: { type: "primary", size: "large", round: "" },
                      on: { click: _vm.close }
                    },
                    [_vm._v("关闭")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }