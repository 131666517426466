"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Choice',
  props: {
    question: {
      type: Object,
      require: true
    },
    solution: {
      type: [Boolean, Object],
      require: false
    }
  },
  computed: { ...(0, _vuex.mapState)({
      answers: function (state) {
        if (state.exercise.answerList[this.question.poetrySubjectId]) {
          return state.exercise.answerList[this.question.poetrySubjectId];
        }

        return [];
      }
    }),

    // 是否多选
    multiple() {
      return this.question.answerCount > 1;
    },

    // 按钮颜色
    optType() {
      return function (index) {
        if (!this.solution) {
          return this.answers.indexOf(index) > -1 ? 'info' : 'default';
        }

        if (this.answers.indexOf(index) > -1) {
          if (this.parseSolution.answer.indexOf(index) > -1) {
            return 'success';
          } else {
            return 'danger';
          }
        }

        return 'default';
      };
    },

    parseSolution() {
      if (!this.solution) {
        return false;
      }

      const {
        options
      } = this.question;
      const {
        answers
      } = this.solution; // 获取答案选项索引

      const answer = [];

      for (const item of answers) {
        for (let i = 0; i < options.length; i++) {
          if (item.answer === options[i].subjectOption) {
            answer.push(i);
          }
        }
      }

      return {
        answerStatus: this.solution.answerStatus,
        answer
      };
    }

  },
  methods: {
    select(index) {
      if (this.solution) {
        return;
      }

      let answers = this.answers;
      const answerIndex = answers.indexOf(index);

      if (!this.multiple) {
        if (answerIndex > -1) {
          answers = [];
        } else {
          answers = [index];
        }
      } else {
        // 多选
        if (answerIndex > -1) {
          answers.splice(answerIndex, 1);
        } else {
          answers.push(index);
        }
      } // 设置答案


      this.$store.commit('SET_ANSWER', {
        poetrySubjectId: this.question.poetrySubjectId,
        answer: answers
      });
    }

  }
};
exports.default = _default;