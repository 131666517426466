"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
var _default = {
  name: 'Fillin',
  props: {
    question: {
      type: Object,
      require: true
    },
    solution: {
      type: [Boolean, Object],
      require: false
    }
  },

  data() {
    return {
      autosize: {
        minHeight: 20,
        maxHeight: 160
      }
    };
  },

  mounted() {// console.log(this.answers)
  },

  computed: { ...(0, _vuex.mapState)({
      answers: function (state) {
        console.log(this.solution && this.solution.answerStatus === 1 && this.solution.userAnswers);

        if (state.exercise.answerList[this.question.poetrySubjectId]) {
          return state.exercise.answerList[this.question.poetrySubjectId];
        } else if (this.solution && this.solution.answerStatus === 1 && this.solution.userAnswers) {
          // 已做的题显示答案
          return this.solution.userAnswers.map(item => item.answer);
        }

        return [];
      }
    })
  },
  methods: {
    commitAnswer(index, val) {
      if (this.solution) {
        return;
      } // 设置答案


      const answers = this.answers;
      answers[index] = val;
      this.$store.commit('SET_ANSWER', {
        poetrySubjectId: this.question.poetrySubjectId,
        answer: answers
      });
    }

  }
};
exports.default = _default;