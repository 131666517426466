"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Topic',
  props: {
    // 题目标题
    title: {
      type: String,
      require: true
    },
    volume: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  },
  methods: {
    play() {
      if (this.volume === '') {
        this.$toast.error('音频不存在');
        return;
      }

      if (this.$store.state.exercise.volumePlayer) {
        this.$store.state.exercise.volumePlayer.pause();
        this.$store.commit('SET_VOLUME_PLAYER', null);
        return;
      }

      const player = new Audio(this.volume);
      this.$store.commit('SET_VOLUME_PLAYER', player);
      player.play();
      player.addEventListener('ended', () => {
        this.$store.commit('SET_VOLUME_PLAYER', null);
      });
    }

  }
};
exports.default = _default;