var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { ref: "list", staticClass: "grade-list", attrs: { id: "list" } },
      [
        _c(
          "van-tabs",
          {
            attrs: {
              color: "#2eaaf7",
              background: "#f5f5f5",
              "line-width": "50",
              "line-height": "5",
              "title-inactive-color": "#8c8c8c",
              "title-active-color": "#000"
            },
            on: { change: _vm.changeGrade },
            model: {
              value: _vm.nowGrade,
              callback: function($$v) {
                _vm.nowGrade = $$v
              },
              expression: "nowGrade"
            }
          },
          _vm._l(_vm.gradeList, function(grade) {
            return _c("van-tab", {
              key: grade.code,
              attrs: { title: grade.codename, name: grade.code }
            })
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "question-list" },
      [
        _c(
          "van-row",
          { attrs: { gutter: "15", type: "flex" } },
          _vm._l(_vm.questionTypeList, function(item) {
            return _c("van-col", { key: item.code, attrs: { span: "8" } }, [
              _c(
                "div",
                { class: ["question-item", "status-" + item.status] },
                [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: require("assets/imgs/icons/" +
                        _vm.icons[item.code] +
                        ".svg"),
                      alt: ""
                    }
                  }),
                  _c("p", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.codename))
                  ]),
                  _c(
                    "van-button",
                    {
                      staticClass: "btn transparent",
                      attrs: {
                        to:
                          "/record?level=" +
                          _vm.nowGrade +
                          "&type=" +
                          item.code,
                        type: "primary",
                        size: "small",
                        round: ""
                      }
                    },
                    [_vm._v(" 答题记录 ")]
                  ),
                  _c(
                    "van-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary", size: "small", round: "" },
                      on: {
                        click: function($event) {
                          return _vm.start(item.code)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(item.status === 1 ? "继续" : "开始") +
                          "答题 "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }