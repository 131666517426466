"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auth = auth;
exports.showConfirm = showConfirm;
exports.isWeixin = isWeixin;
exports.second2Time = second2Time;
exports.isIOS = isIOS;
exports.toPercent = toPercent;

var _config = require("@/config");

// 公共函数
// 登录
function auth(url) {
  url = encodeURIComponent(url);
  const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${_config.APPID}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=STATE#wechat_redire`;
  location.replace(authUrl);
} // 重写确认框


function showConfirm(Dialog, opts, confirmCallback, cancelCallback) {
  if (!Dialog) {
    return false;
  }

  const defaultOpts = {
    className: 'confirm-container',
    title: '',
    message: '默认提示',
    theme: 'round-button',
    showConfirmButton: true,
    showCancelButton: true,
    closeOnPopstate: false // 必须为false，否则第二次不弹出 https://github.com/youzan/vant/issues/7625

  };
  Dialog.confirm({ ...defaultOpts,
    ...opts
  }).then(() => {
    // confirm
    confirmCallback && confirmCallback();
  }).catch(() => {
    // cancel
    Dialog.close();
    cancelCallback && cancelCallback();
  });
} // 判断是否在微信打开


function isWeixin() {
  const ua = window.navigator.userAgent.toLowerCase();

  if (ua.indexOf('micromessenger') !== -1) {
    return true;
  }

  return false;
} // 秒转化为时间


function second2Time(second, format = '') {
  let h = Math.floor(second / 3600);
  let m = Math.floor(second / 60 % 60);
  let s = Math.floor(second % 60);
  h = h < 10 ? `0${h}` : h;
  m = m < 10 ? `0${m}` : m;
  s = s < 10 ? `0${s}` : s;

  if (format === 'chinese') {
    return (h > 0 ? `${h}时` : '') + `${m}分${s}秒`;
  } else {
    return (h > 0 ? `${h}:` : '') + `${m}:${s}`;
  }
} // 是否是IOS


function isIOS() {
  const ua = navigator.userAgent.toLowerCase();

  if (/iphone|ipad|ipod/.test(ua)) {
    return true;
  }

  return false;
} // 转化百分比


function toPercent(number, symbol = true) {
  let percent = number * 100;

  if (percent % 1 !== 0) {
    percent = percent.toFixed(2);
  }

  return `${percent}${symbol ? '%' : ''}`;
}