"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _NavItem = _interopRequireDefault(require("@/components/NavItem"));

var _subject = require("@/api/subject");

//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    NavItem: _NavItem.default
  },

  data() {
    return {
      navList: [{
        title: '常规练习',
        desc: '',
        icon: require('assets/imgs/icons/order.png'),
        url: '/order',
        type: 'important'
      }, {
        title: '综合练习',
        desc: '',
        icon: require('assets/imgs/icons/random.png'),
        url: '/random',
        type: 'important'
      }, {
        title: '错题集',
        desc: '',
        icon: require('assets/imgs/icons/error.png'),
        url: '/exercise',
        clickCallback: () => {
          this.$store.dispatch('startErrorExericse').then(() => {
            this.$router.push('/exercise');
          }).catch(error => {
            this.$toast(error);
          });
        }
      }, {
        title: '题目收藏',
        desc: '',
        icon: require('assets/imgs/icons/collect.png'),
        url: '/exercise',
        clickCallback: () => {
          this.$store.dispatch('startCollectExericse').then(() => {
            this.$router.push('/exercise');
          }).catch(error => {
            this.$toast(error);
          });
        }
      }, {
        title: '答题分析',
        desc: '',
        icon: require('assets/imgs/icons/ana.png'),
        url: '/analysis'
      }, {
        title: '题目说明',
        desc: '',
        icon: require('assets/imgs/icons/info.png'),
        url: '/info'
      }, {
        title: '个人信息',
        desc: '',
        icon: require('assets/imgs/icons/user.png'),
        url: '/user'
      }]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    // 获取首页数据
    getData() {
      (0, _subject.getSubjectStatistics)().then(res => {
        const {
          collectSubjectCount,
          drillByAnswerCount = 0,
          drillBySubjectCount,
          errorBySubjectCount
        } = res.data;
        const list = this.navList;
        list[0].desc = `(${drillByAnswerCount}/${drillBySubjectCount})`;
        list[2].desc = `(${errorBySubjectCount})`;
        list[3].desc = `(${collectSubjectCount})`;
        this.navList = list;
      });
    }

  }
};
exports.default = _default;