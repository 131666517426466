import { render, staticRenderFns } from "./wechat.vue?vue&type=template&id=6107c625&scoped=true&"
import script from "./wechat.vue?vue&type=script&lang=js&"
export * from "./wechat.vue?vue&type=script&lang=js&"
import style0 from "./wechat.vue?vue&type=style&index=0&id=6107c625&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6107c625",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6107c625')) {
      api.createRecord('6107c625', component.options)
    } else {
      api.reload('6107c625', component.options)
    }
    module.hot.accept("./wechat.vue?vue&type=template&id=6107c625&scoped=true&", function () {
      api.rerender('6107c625', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/subscribe/wechat.vue"
export default component.exports