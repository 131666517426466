var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-form",
    { staticClass: "container" },
    _vm._l(this.question.answerCount, function(item) {
      return _c("van-field", {
        key: item,
        class: [
          "fill-in-input",
          !_vm.solution || !_vm.solution.userAnswers
            ? ""
            : _vm.solution.answerStatus === 1 ||
              (_vm.solution.userAnswers[item - 1] &&
                _vm.solution.answers[item - 1].answer ===
                  _vm.solution.userAnswers[item - 1].answer)
            ? "success"
            : "error"
        ],
        attrs: {
          placeholder: !_vm.solution ? "请点击输入答案" : "未作答",
          type: "text",
          disabled: !!_vm.solution,
          value: _vm.answers[item - 1]
        },
        on: {
          input: function($event) {
            return _vm.commitAnswer(item - 1, $event)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }