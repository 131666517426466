"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style/less");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _axios = _interopRequireDefault(require("axios"));

var _store = _interopRequireDefault(require("@/store"));

var _router = _interopRequireDefault(require("@/router"));

var _constant = require("@/utils/constant");

var _common = require("@/utils/common");

var _config = require("@/config");

// 根据环境不同引入不同api地址
// create an axios instance
const service = _axios.default.create({
  baseURL: _config.baseApi,
  // url = base api url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 5000 // request timeout

}); // request拦截器 request interceptor


service.interceptors.request.use(config => {
  // 不传递默认开启loading
  if (!config.hideloading) {
    // loading
    _toast.default.loading({
      forbidClick: true
    });
  } // 开发环境url拼接


  if (process.env.VUE_APP_ENV === 'development') {
    config.url = '/api' + config.url;
  } // 携带token


  if (config.needLogin !== false && _store.default.getters.token) {
    config.headers['authority-token'] = _store.default.getters.token;
  } // 预生产环境添加一个请求头


  if (process.env.VUE_APP_ENV === 'staging') {
    config.headers['x-current-env'] = 'Blue';
  }

  return config;
}, error => {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // respone拦截器

service.interceptors.response.use(response => {
  _toast.default.clear();

  const res = response.data;

  if (res.code && res.code !== _constant.codes.SUCCESS) {
    switch (res.code) {
      // 重新登录
      case _constant.codes.EXPIRED:
      case _constant.codes.AUTH_FAIL:
        if ((0, _common.isWeixin)()) {
          (0, _common.auth)(window.location.href);
        } else {
          _router.default.push('/wechatEnv');
        }

        return;
      // 未关注公众号

      case _constant.codes.UN_SUBSCRIBE:
        _router.default.push('/subscribe');

        return Promise.reject('');
      // 需要分享

      case _constant.codes.NEED_SHARE:
        _store.default.commit('SET_DISPLAY_SHARE', true);

        return Promise.reject('');
      // 需要填写用户信息

      case _constant.codes.NEED_FILL_INFO:
        return Promise.resolve(res);
    } // 提示错误


    if (!response.config.hideError) {
      (0, _toast.default)(res.message);
    }

    return Promise.reject(res || 'error');
  } else {
    return Promise.resolve(res);
  }
}, error => {
  _toast.default.clear();

  console.log('err' + error); // for debug

  return Promise.reject(error);
});
var _default = service;
exports.default = _default;