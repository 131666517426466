"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = require("@/utils/constant");

const getters = {
  // 登录状态
  token: state => {
    // 优先从storage获取
    const token = sessionStorage.getItem('token');

    if (token) {
      return token;
    }

    return state.miniLoginInfo.token;
  },
  // 是否是顺序练习
  isOrder: state => state.exercise.exerciseType === _constant.exerciseTypes.order,
  // 是否是随机练习
  isRandom: state => state.exercise.exerciseType === _constant.exerciseTypes.random,
  // 是否是错题集练习
  isError: state => state.exercise.exerciseType === _constant.exerciseTypes.error,
  // 是否是收藏集练习
  isCollect: state => state.exercise.exerciseType === _constant.exerciseTypes.collect,
  // 答题卡标题
  answerCardTitle: state => {
    if (state.exercise.displayRandomResult) {
      return '答题结果';
    }

    if (state.exercise.exerciseType === _constant.exerciseTypes.order) {
      return '常规练习';
    }

    if (state.exercise.exerciseType === _constant.exerciseTypes.random) {
      return '综合练习';
    }

    if (state.exercise.exerciseType === _constant.exerciseTypes.error) {
      return '错题集';
    }

    if (state.exercise.exerciseType === _constant.exerciseTypes.collect) {
      return '收藏集';
    }

    return '';
  }
};
var _default = getters;
exports.default = _default;