var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exercise" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showExercise,
              expression: "showExercise"
            }
          ],
          staticClass: "exercise-container"
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "container-inline" }, [
              _c(
                "div",
                { staticClass: "top-operates" },
                [
                  _c(
                    "van-row",
                    [
                      _c(
                        "van-col",
                        { staticClass: "pre-wrapper", attrs: { span: "8" } },
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "btn collect-btn",
                              class:
                                _vm.nowQuestion && _vm.nowQuestion.isCollect
                                  ? "collected"
                                  : "",
                              attrs: {
                                icon:
                                  _vm.nowQuestion && _vm.nowQuestion.isCollect
                                    ? _vm.collected
                                    : _vm.unCollect,
                                size: "small",
                                round: ""
                              },
                              on: { click: _vm.setQuestionCollectStatus }
                            },
                            [_vm._v(" 收藏 ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "van-col",
                        { staticClass: "question-type", attrs: { span: "8" } },
                        [_vm._v(" " + _vm._s(_vm.questionType) + " ")]
                      ),
                      _c(
                        "van-col",
                        { staticClass: "record-wrapper", attrs: { span: "8" } },
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "btn transparent record-content",
                              attrs: {
                                type: "warning",
                                size: "small",
                                icon: require("assets/imgs/icons/list.svg"),
                                round: ""
                              },
                              on: { click: _vm.changeAnswerCard }
                            },
                            [
                              _c("span", { staticClass: "question-index" }, [
                                _vm._v(
                                  _vm._s(_vm.nowQuestionNum) +
                                    " / " +
                                    _vm._s(_vm.questionList.length)
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-row",
                    { staticClass: "progress" },
                    [
                      _c("van-progress", {
                        ref: "progress",
                        attrs: {
                          color: "#ffd397",
                          "show-pivot": false,
                          percentage: _vm.progressPercent
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isRandom
                    ? _c(
                        "van-row",
                        { attrs: { type: "flex", justify: "center" } },
                        [
                          _c(
                            "div",
                            { staticClass: "timing" },
                            [
                              _c(
                                "van-row",
                                {
                                  staticClass: "timing-content",
                                  attrs: {
                                    type: "flex",
                                    justify: "center",
                                    gutter: "8"
                                  }
                                },
                                [
                                  _c(
                                    "van-col",
                                    { staticClass: "timing-icon" },
                                    [
                                      _c("van-icon", {
                                        attrs: {
                                          name: require("assets/imgs/icons/time.svg")
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("van-col", { staticClass: "timer" }, [
                                    _vm._v(_vm._s(_vm.timerStr))
                                  ])
                                ],
                                1
                              ),
                              _c("img", {
                                staticClass: "timing-bg",
                                attrs: {
                                  src: require("assets/imgs/timing_bg.png"),
                                  alt: ""
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "question-container" },
                _vm._l(_vm.questionList, function(item, index) {
                  return _c(
                    "van-row",
                    { key: index },
                    [
                      _c("question", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: index === _vm.nowQuestionIndex,
                            expression: "index === nowQuestionIndex"
                          }
                        ],
                        attrs: {
                          question: item,
                          solution: _vm.originSolution,
                          showErrorCount: _vm.isError
                        },
                        on: { "remove-handle": _vm.setSolution }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _vm.solution
                ? _c(
                    "div",
                    {
                      staticClass: "solution-container",
                      class: { choice: _vm.isChoice }
                    },
                    [
                      _c("van-divider", [_vm._v("题目解析")]),
                      _c(
                        "van-row",
                        { staticClass: "solution" },
                        [
                          _vm.isChoice
                            ? _c(
                                "van-col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "van-row",
                                    {
                                      staticClass: "solution-item",
                                      attrs: { type: "flex" }
                                    },
                                    [
                                      _c(
                                        "van-col",
                                        {
                                          staticClass: "label",
                                          attrs: { span: "13" }
                                        },
                                        [_vm._v("你的选择")]
                                      ),
                                      _c(
                                        "van-col",
                                        {
                                          staticClass: "value",
                                          attrs: { span: "11" }
                                        },
                                        [_vm._v(_vm._s(_vm.yourAnswer))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "van-col",
                            { attrs: { span: _vm.isChoice ? 12 : 24 } },
                            [
                              _c(
                                "van-row",
                                {
                                  staticClass: "solution-item",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "van-col",
                                    {
                                      staticClass: "label",
                                      attrs: { span: _vm.isChoice ? 13 : 7 }
                                    },
                                    [_vm._v("正确答案")]
                                  ),
                                  _c(
                                    "van-col",
                                    {
                                      staticClass: "value",
                                      attrs: { span: _vm.isChoice ? 11 : 17 }
                                    },
                                    [_vm._v(_vm._s(_vm.solution.answer))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.solution.parsing
                        ? _c("van-row", { staticClass: "parsing" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("答案解析")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.solution.parsing))
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _c(
            "div",
            { staticClass: "bottom-operates" },
            [
              _c(
                "van-row",
                { attrs: { type: "flex", align: "center" } },
                [
                  _c(
                    "van-col",
                    {
                      class: [
                        "question-option",
                        {
                          "option-disabed":
                            _vm.nowQuestionIndex === 0 ||
                            (this.viewErrorQuestion &&
                              this.nowQuestionIndex <= this.errorRange[0])
                        }
                      ],
                      attrs: { span: "10" },
                      on: { click: _vm.prevQuestion }
                    },
                    [_vm._v("上一题")]
                  ),
                  _c(
                    "van-col",
                    {
                      class: [
                        "question-option",
                        {
                          "option-disabed":
                            this.solution &&
                            (_vm.nowQuestionIndex ===
                              _vm.questionList.length - 1 ||
                              (this.viewErrorQuestion &&
                                this.nowQuestionIndex >= this.errorRange[1]))
                        }
                      ],
                      attrs: { span: "10", offset: "4" },
                      on: { click: _vm.nextQuestion }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          this.isRandom &&
                            !this.solution &&
                            _vm.nowQuestionIndex === _vm.questionList.length - 1
                            ? "提交"
                            : "下一题"
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAnswerCard,
              expression: "showAnswerCard"
            }
          ],
          staticClass: "answer-card-wrapper"
        },
        [
          _c("answer-card", {
            attrs: {
              commitAnswer: _vm.commitAnswerCard,
              close: _vm.changeAnswerCard,
              select: _vm.selectQuestion
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRandomResult,
              expression: "showRandomResult"
            }
          ],
          staticClass: "random-result-wrapper"
        },
        [
          _c(
            "answer-card",
            {
              attrs: {
                type: "result",
                close: _vm.changeAnswerCard,
                select: _vm.selectQuestion,
                time: _vm.timerStrChinese
              }
            },
            [
              _c(
                "div",
                { staticClass: "random-result-header" },
                [
                  _c("van-row", { staticClass: "correct-rate-wrapper" }, [
                    _c("img", {
                      attrs: {
                        src: require("assets/imgs/bg_finish.svg"),
                        alt: ""
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "correct-rate" },
                      [
                        _c("van-row", { staticClass: "rate" }, [
                          _vm._v(_vm._s(_vm.rightRate)),
                          _c("span", [_vm._v("%")])
                        ]),
                        _c("van-row", { staticClass: "label" }, [
                          _vm._v("正确率")
                        ])
                      ],
                      1
                    )
                  ]),
                  false
                    ? _c("van-row", { staticClass: "result-tips" }, [
                        _vm._v(_vm._s(_vm.resultTips))
                      ])
                    : _vm._e(),
                  _c("van-divider")
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmpty,
              expression: "showEmpty"
            }
          ],
          staticClass: "no-question"
        },
        [_c("empty")],
        1
      ),
      _c("share-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }