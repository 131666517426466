"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.codes = exports.questionTypeTitles = exports.exerciseTypes = exports.questionComponents = exports.questionTypes = void 0;
// 常量
// 题型类型
const questionTypes = {
  1: 'choice',
  // 单选
  2: 'multiple',
  // 多选
  3: 'fillin',
  // 填空
  4: 'sentence',
  // 对句
  5: 'nine',
  // 九宫格
  6: 'twelve',
  // 十二宫格
  7: 'twenty',
  // 二十宫格
  8: 'choice' // 判断题

}; // 题型类型标题

exports.questionTypes = questionTypes;
const questionTypeTitles = {
  1: '单选题',
  // 单选
  2: '多选题',
  // 多选
  3: '填空题',
  // 填空
  4: '对句题',
  // 对句
  5: '九宫格题',
  // 九宫格
  6: '十二宫格题',
  // 十二宫格
  7: '二十宫格题',
  // 二十宫格
  8: '判断题' // 判断题

}; // 题型组件映射

exports.questionTypeTitles = questionTypeTitles;
const questionComponents = {
  1: 'choice',
  // 单选
  2: 'choice',
  // 多选
  3: 'fillin',
  // 填空
  4: 'fillin',
  // 对句
  5: 'grid',
  // 九宫格
  6: 'grid',
  // 十二宫格
  7: 'grid',
  // 二十宫格
  8: 'choice' // 判断题

}; // 练习模式

exports.questionComponents = questionComponents;
const exerciseTypes = {
  order: 1,
  // 顺序答题
  random: 2,
  // 随机答题
  error: 3,
  // 错题集
  collect: 4 // 收藏集

}; // codes

exports.exerciseTypes = exerciseTypes;
const codes = {
  SUCCESS: 200,
  // 成功
  EXPIRED: 401,
  // 登录已过期
  SERVER_ERROR: 500,
  // 服务器异常
  UN_SUBSCRIBE: 1001,
  // 未关注公众号
  NEED_SHARE: 1002,
  // 答题次数已用完，请分享题目
  NEED_FILL_INFO: 1003,
  // 需要填写用户信息
  INVALID: 20019,
  // 请登录后操作，从没登陆过，从非正常途径进入
  AUTH_FAIL: 20020 // 会话失效，登陆后登陆过期失效，设备认证失败

};
exports.codes = codes;