var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-row", { attrs: { type: "flex", justify: "center" } }, [
        _c("div", { staticClass: "start-btn" }, [
          _c("div", { staticClass: "start", on: { click: _vm.start } }, [
            _vm._v("开始答题")
          ]),
          _c("img", {
            attrs: {
              src: require("assets/imgs/random_btn_shadow.svg"),
              alt: ""
            }
          })
        ])
      ]),
      _c("van-row", [_c("van-divider", [_vm._v("答题记录")])], 1),
      _vm.preData
        ? _c(
            "van-row",
            [
              _c(
                "van-row",
                [_c("data-card", { attrs: { data: _vm.preData } })],
                1
              ),
              _c(
                "van-row",
                [
                  _c(
                    "van-row",
                    [
                      _c("van-col", { attrs: { span: "24" } }, [
                        _c(
                          "div",
                          { staticClass: "label" },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.dataDetail.label))]),
                            _c(
                              "van-button",
                              {
                                staticClass: "detail-btn transparent",
                                attrs: {
                                  round: "",
                                  type: "primary",
                                  size: "small"
                                },
                                on: { click: _vm.viewPreData }
                              },
                              [_vm._v("查看结果")]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "value detail" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.dataDetail.value.latelyRespondTime)
                            )
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.dataDetail.value.latelyAccuracy))
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dataDetail.value
                                  .latelyRespondElapsedTimeName
                              )
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "van-row",
            { staticClass: "empty-container" },
            [_c("empty", [_vm._v("暂无记录")])],
            1
          ),
      _c("share-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }