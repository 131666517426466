"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOpenIdByCode = getOpenIdByCode;
exports.getUserInfo = getUserInfo;
exports.saveUserInfo = saveUserInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取用户openid
function getOpenIdByCode(params) {
  return (0, _request.default)({
    url: '/getOpenIdByCode',
    params
  });
} // 获取用户信息


function getUserInfo() {
  return (0, _request.default)({
    url: '/getUserSchoolInfo'
  });
} // 保存用户信息


function saveUserInfo(data) {
  return (0, _request.default)({
    url: '/saveUserSchoolInfo',
    method: 'POST',
    data
  });
}