"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/picker/style/less");

var _picker = _interopRequireDefault(require("vant/es/picker"));

require("vant/es/popup/style/less");

var _popup = _interopRequireDefault(require("vant/es/popup"));

var _vue = _interopRequireDefault(require("vue"));

var _subject = require("@/api/subject");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.use(_popup.default);

_vue.default.use(_picker.default);

var _default = {
  name: 'User',

  data() {
    return {
      from: '',
      nowPicker: null,
      showPickers: {
        school: false,
        grade: false,
        class: false
      },
      pickers: ['school', 'grade', 'class'],
      list: {
        school: [],
        grade: [],
        class: []
      },
      user: {
        schoolId: 0,
        gradeId: 0,
        classId: 0,
        studentName: '',
        schoolName: '',
        gradeName: '',
        className: ''
      }
    };
  },

  created() {
    this.init();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from;
    });
  },

  methods: {
    async init() {
      await this.getUserInfo();
      this.getList('school');

      if (!this.user || !this.user.schoolId) {
        return;
      }

      await this.getList('grade');
      this.getList('class');
    },

    showPicker(label) {
      if (label === 'grade' && !this.user.schoolId) {
        this.$toast('请先选择学校');
        return;
      }

      if (label === 'class' && !this.user.gradeId) {
        this.$toast('请先选择年级');
        return;
      }

      this.nowPicker = label;
      this.showPickers[label] = true;

      if (this.list[label].length === 0) {
        this.getList(label);
      }
    },

    onCancel() {
      this.showPickers[this.nowPicker] = false;
      this.nowPicker = null;
    },

    onConfirm(picker) {
      this.user[this.nowPicker + 'Id'] = picker.code;
      this.user[this.nowPicker + 'Name'] = picker.name;

      if (this.nowPicker === 'school') {
        this.list.grade = [];
        this.list.class = [];
        this.user.gradeId = 0;
        this.user.classId = 0;
        this.user.gradeName = '';
        this.user.className = '';
      } else if (this.nowPicker === 'grade') {
        this.list.class = [];
        this.user.classId = 0;
        this.user.className = '';
      }

      this.onCancel();
    },

    onSubmit() {
      const tips = {
        schoolName: '学校',
        gradeName: '年级',
        className: '班级',
        studentName: '姓名'
      };

      for (const key in this.user) {
        if (key.indexOf('Name') !== -1 && !this.user[key]) {
          this.$toast(`${tips[key]}不能为空`);
          return;
        }
      }

      (0, _user.saveUserInfo)(this.user).then(res => {
        if (res.code !== 200) {
          this.$toast(res.message);
          return;
        }

        this.$toast('保存成功');
        setTimeout(() => {
          if (!this.from.name) {
            this.$router.push('/');
            return;
          }

          if (this.from.name === 'Exercise') {
            this.$router.push('/order');
            return;
          }

          this.$router.go(-1);
        }, 1000);
      });
    },

    getUserInfo() {
      (0, _user.getUserInfo)().then(res => {
        this.user = res.data;
        return this.user;
      });
    },

    getList(label) {
      switch (label) {
        case 'school':
          (0, _subject.getSchoolList)().then(res => {
            this.list.school = res.data;
          });
          break;

        case 'grade':
          (0, _subject.getGradeList)({
            schoolId: this.user.schoolId
          }).then(res => {
            this.list.grade = res.data;
          });
          break;

        case 'class':
          (0, _subject.getClassList)({
            schoolId: this.user.schoolId,
            gradeId: this.user.gradeId
          }).then(res => {
            this.list.class = res.data;
          });
          break;

        default:
          console.log('label invaild');
      }
    }

  }
};
exports.default = _default;