"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getQuestionList = getQuestionList;
exports.commitMiniAnswer = commitMiniAnswer;
exports.getExamResult = getExamResult;

var _request = _interopRequireDefault(require("@/utils/request"));

// 登录
function login(params) {
  return (0, _request.default)({
    url: '/tradeVoucher',
    params,
    needLogin: false
  });
} // 获取题目


function getQuestionList(params) {
  return (0, _request.default)({
    url: '/getSubjectList',
    params
  });
} // 提交答题卡


function commitMiniAnswer(data) {
  return (0, _request.default)({
    url: '/doBatchSubjectRespond',
    method: 'post',
    data
  });
} // 获取答题结果


function getExamResult(params) {
  return (0, _request.default)({
    url: '/getSubjectSheet',
    params
  });
}