"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constantRouterMap = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

/**
 * 基础路由
 * @type { *[] }
 */
const constantRouterMap = [{
  path: '/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/layouts/index'))),
  redirect: '/mini',
  meta: {
    title: '首页',
    keepAlive: false
  },
  children: [{
    path: '/home',
    name: 'Home',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/home/index'))),
    meta: {
      title: '首页',
      keepAlive: false
    }
  }, {
    path: '/auth',
    name: 'Auth',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/auth/index'))),
    meta: {
      title: '微信授权',
      keepAlive: false
    }
  }, {
    path: '/order',
    name: 'Order',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/index'))),
    meta: {
      title: '常规练习',
      keepAlive: false
    }
  }, {
    path: '/record',
    name: 'OrderRecord',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/record'))),
    meta: {
      title: '答题记录',
      keepAlive: false
    }
  }, {
    path: '/random',
    name: 'Random',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/random/index'))),
    meta: {
      title: '综合练习',
      keepAlive: false
    }
  }, {
    path: '/exercise',
    name: 'Exercise',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/exercise/index'))),
    meta: {
      title: '练习',
      keepAlive: false
    }
  }, {
    path: '/info',
    name: 'Info',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/about/info'))),
    meta: {
      title: '题目说明',
      keepAlive: false
    }
  }, {
    path: '/analysis',
    name: 'Analysis',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/analysis/index'))),
    meta: {
      title: '答题分析',
      keepAlive: false
    }
  }, {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/subscribe/index'))),
    meta: {
      title: '关注公众号',
      keepAlive: false,
      auth: false // 不需要登录

    }
  }, {
    path: '/wechatEnv',
    name: 'WechatEnv',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/subscribe/wechat'))),
    meta: {
      title: '关注公众号',
      keepAlive: false,
      auth: false // 不需要登录

    }
  }, {
    path: '/user',
    name: 'User',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/user/index'))),
    meta: {
      title: '个人信息',
      keepAlive: false
    }
  }, // 小程序检测入口
  {
    path: '/mini',
    name: 'Mini',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/mini/index'))),
    meta: {
      title: '检测',
      keepAlive: false
    }
  }]
}];
exports.constantRouterMap = constantRouterMap;