var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPage
    ? _c(
        "div",
        { staticClass: "exercise" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showExercise,
                  expression: "showExercise"
                }
              ],
              staticClass: "exercise-container"
            },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "container-inline" }, [
                  _c(
                    "div",
                    { staticClass: "top-operates" },
                    [
                      _c(
                        "van-row",
                        { staticClass: "top-options" },
                        [
                          !_vm.displayRandomResult
                            ? _c(
                                "van-col",
                                {
                                  staticClass: "pre-wrapper countdown",
                                  attrs: { span: "8" }
                                },
                                [
                                  _c(
                                    "van-row",
                                    { staticClass: "timing" },
                                    [
                                      _c(
                                        "van-col",
                                        [
                                          _c("van-icon", {
                                            attrs: {
                                              name: require("assets/imgs/icons/time.svg")
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("van-col", [
                                        _vm._v(_vm._s(_vm.timerStr))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "van-col",
                                {
                                  staticClass: "close-solution",
                                  attrs: { span: "8" }
                                },
                                [
                                  _c(
                                    "van-button",
                                    {
                                      staticClass: "transparent",
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        plain: "",
                                        round: ""
                                      },
                                      on: { click: _vm.closeSolution }
                                    },
                                    [_vm._v("退出解析 ")]
                                  )
                                ],
                                1
                              ),
                          _c(
                            "van-col",
                            {
                              staticClass: "question-type",
                              attrs: { span: "8" }
                            },
                            [_vm._v(" " + _vm._s(_vm.questionType) + " ")]
                          ),
                          _c(
                            "van-col",
                            {
                              staticClass: "record-wrapper",
                              attrs: { span: "8" }
                            },
                            [
                              _c(
                                "van-button",
                                {
                                  staticClass: "btn transparent record-content",
                                  attrs: {
                                    type: "warning",
                                    size: "small",
                                    icon: require("assets/imgs/icons/list.svg"),
                                    round: ""
                                  },
                                  on: { click: _vm.changeAnswerCard }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "question-index" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.nowQuestionNum) +
                                          " / " +
                                          _vm._s(_vm.questionList.length)
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "van-row",
                        { staticClass: "progress" },
                        [
                          _c("van-progress", {
                            ref: "progress",
                            attrs: {
                              color: "#FECE32",
                              "show-pivot": false,
                              percentage: _vm.progressPercent
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "question-container" },
                    _vm._l(_vm.questionList, function(item, index) {
                      return _c(
                        "van-row",
                        { key: index },
                        [
                          _c("question", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index === _vm.nowQuestionIndex,
                                expression: "index === nowQuestionIndex"
                              }
                            ],
                            attrs: {
                              question: item,
                              solution: _vm.originSolution
                            },
                            on: { "remove-handle": _vm.setSolution }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm.solution
                    ? _c(
                        "div",
                        {
                          staticClass: "solution-container",
                          class: { choice: _vm.isChoice }
                        },
                        [
                          _c("van-divider", [_vm._v("题目解析")]),
                          _c(
                            "van-row",
                            { staticClass: "solution" },
                            [
                              _vm.isChoice
                                ? _c(
                                    "van-col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "van-row",
                                        {
                                          staticClass: "solution-item",
                                          attrs: { type: "flex" }
                                        },
                                        [
                                          _c(
                                            "van-col",
                                            {
                                              staticClass: "label",
                                              attrs: { span: "13" }
                                            },
                                            [_vm._v("你的选择")]
                                          ),
                                          _c(
                                            "van-col",
                                            {
                                              staticClass: "value",
                                              attrs: { span: "11" }
                                            },
                                            [_vm._v(_vm._s(_vm.yourAnswer))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "van-col",
                                { attrs: { span: _vm.isChoice ? 12 : 24 } },
                                [
                                  _c(
                                    "van-row",
                                    {
                                      staticClass: "solution-item",
                                      attrs: { type: "flex" }
                                    },
                                    [
                                      _c(
                                        "van-col",
                                        {
                                          staticClass: "label",
                                          attrs: { span: _vm.isChoice ? 13 : 7 }
                                        },
                                        [_vm._v("正确答案")]
                                      ),
                                      _c(
                                        "van-col",
                                        {
                                          staticClass: "value",
                                          attrs: {
                                            span: _vm.isChoice ? 11 : 17
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.solution.answer))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.solution.parsing
                            ? _c("van-row", { staticClass: "parsing" }, [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("答案解析")
                                ]),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.solution.parsing))
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _c(
                "div",
                { staticClass: "bottom-operates" },
                [
                  _c(
                    "van-row",
                    { attrs: { type: "flex", align: "center" } },
                    [
                      _c(
                        "van-col",
                        {
                          class: [
                            "question-option",
                            {
                              "option-disabed":
                                _vm.nowQuestionIndex === 0 ||
                                (this.viewErrorQuestion &&
                                  this.nowQuestionIndex <= this.errorRange[0])
                            }
                          ],
                          attrs: { span: "10" },
                          on: { click: _vm.prevQuestion }
                        },
                        [_vm._v("上一题")]
                      ),
                      _c(
                        "van-col",
                        {
                          class: [
                            "question-option",
                            {
                              "option-disabed":
                                this.solution &&
                                (_vm.nowQuestionIndex ===
                                  _vm.questionList.length - 1 ||
                                  (this.viewErrorQuestion &&
                                    this.nowQuestionIndex >=
                                      this.errorRange[1])),
                              submit:
                                !this.solution &&
                                _vm.nowQuestionIndex ===
                                  _vm.questionList.length - 1
                            }
                          ],
                          attrs: { span: "10", offset: "4" },
                          on: { click: _vm.nextQuestion }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              !this.solution &&
                                _vm.nowQuestionIndex ===
                                  _vm.questionList.length - 1
                                ? "提交"
                                : "下一题"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAnswerCard,
                  expression: "showAnswerCard"
                }
              ],
              staticClass: "answer-card-wrapper"
            },
            [
              _c("answer-card", {
                attrs: {
                  commitAnswer: _vm.commitAnswerCard,
                  close: _vm.changeAnswerCard,
                  select: _vm.selectQuestion,
                  undoCount: _vm.undoCount
                },
                scopedSlots: _vm._u(
                  [
                    _vm.displayRandomResult
                      ? {
                          key: "bottom",
                          fn: function() {
                            return [
                              _c(
                                "van-row",
                                { attrs: { type: "flex", justify: "center" } },
                                [
                                  _c(
                                    "van-col",
                                    { attrs: { span: "9" } },
                                    [
                                      _c(
                                        "van-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "large",
                                            round: "",
                                            plain: ""
                                          },
                                          on: { click: _vm.closeSolution }
                                        },
                                        [_vm._v("退出解析")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showRandomResult,
                  expression: "showRandomResult"
                }
              ],
              staticClass: "random-result-wrapper"
            },
            [
              _c(
                "answer-card",
                {
                  attrs: {
                    "data-v-result": "",
                    type: "result",
                    close: _vm.changeAnswerCard,
                    select: _vm.selectQuestion,
                    time: _vm.timerStrChinese,
                    undoCount: _vm.undoCount,
                    detectInfo: _vm.detectInfo
                  }
                },
                [
                  _c("div", { staticClass: "exam-count__box" }, [
                    _c("img", {
                      attrs: {
                        src: require("assets/imgs/jieguo_tag_bg.png"),
                        alt: ""
                      }
                    }),
                    _c("div", [
                      _vm._v(
                        "本书已检测次数 (" +
                          _vm._s(_vm.detectInfo.detectedNum) +
                          "/" +
                          _vm._s(_vm.detectInfo.limitDetectNum) +
                          ")"
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { class: ["random-result-header", { fail: !_vm.isPass }] },
                    [
                      _c("van-row", { staticClass: "correct-rate-wrapper" }, [
                        _vm.isPass
                          ? _c("img", {
                              attrs: {
                                src: require("assets/imgs/bg_finish.png"),
                                alt: ""
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: require("assets/imgs/bg_fail.png"),
                                alt: ""
                              }
                            }),
                        _c(
                          "div",
                          { staticClass: "correct-rate" },
                          [
                            _c("van-row", { staticClass: "rate" }, [
                              _vm._v(_vm._s(_vm.rightRate)),
                              _c("span", [_vm._v("分")])
                            ])
                          ],
                          1
                        )
                      ]),
                      _c("van-row", { staticClass: "result-tips" }, [
                        _vm._v(_vm._s(_vm.resultTips))
                      ]),
                      !_vm.isPass
                        ? _c("van-row", { staticClass: "pass-score" }, [
                            _vm._v("通过分数：" + _vm._s(_vm.passScore) + " 分")
                          ])
                        : _vm._e(),
                      _c("van-divider")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              class: [
                "article",
                {
                  show: _vm.nowArticle > 0
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "article-tip", on: { click: _vm.closeArticle } },
                [
                  _c("p", [_vm._v("请阅读以下文章")]),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/imgs/bottom_arrow.png"),
                      alt: ""
                    }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "article-content" },
                [
                  _c("div", { staticClass: "article-title" }, [
                    _vm._v(_vm._s(_vm.article.title))
                  ]),
                  _c("div", {
                    staticClass: "main",
                    domProps: { innerHTML: _vm._s(_vm.article.content) }
                  }),
                  _c(
                    "van-row",
                    {
                      staticClass: "close-btn",
                      attrs: { type: "flex", justify: "center" }
                    },
                    [
                      _c(
                        "van-col",
                        { attrs: { span: "10" } },
                        [
                          _c(
                            "van-button",
                            {
                              attrs: { type: "primary", block: "", round: "" },
                              on: { click: _vm.closeArticle }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.displayRandomResult ? "关闭" : "答题"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmpty,
                  expression: "showEmpty"
                }
              ],
              staticClass: "no-question"
            },
            [_c("empty")],
            1
          ),
          _c("share-dialog")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }