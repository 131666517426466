"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

const state = {
  token: '',
  // 登录状态
  authStatus: false // 防止重复授权

};
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_AUTH_STATUS(state, status) {
    state.authStatus = status;
  }

};
const actions = {
  // 获取openid
  auth({
    commit
  }, payload) {
    commit('SET_AUTH_STATUS', true);
    return new Promise((resolve, reject) => {
      (0, _user.getOpenIdByCode)({
        code: payload.code
      }).then(res => {
        if (res.code === 200) {
          commit('SET_TOKEN', res.data);
          sessionStorage.setItem('token', res.data);
          resolve();
        } else {
          reject(res.message);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

};
var _default = {
  state,
  mutations,
  actions
};
exports.default = _default;