var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "record-container" },
    [
      _c("van-row", [
        _c("img", {
          staticClass: "header-image",
          attrs: { src: require("assets/imgs/bg_record.png"), alt: "" }
        })
      ]),
      _vm.data
        ? _c("van-row", [_c("data-card", { attrs: { data: _vm.data } })], 1)
        : _c("van-row", [_c("empty")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }