var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "answer-card-wrap" },
    [
      _c(
        "div",
        { staticClass: "answer-card" },
        [
          _vm._t("default"),
          _c(
            "van-row",
            { attrs: { type: "flex", align: "center" } },
            [
              _c("van-col", { staticClass: "title" }, [_vm._v("检测情况")]),
              _vm.time
                ? _c("van-col", { staticClass: "finish-time" }, [
                    _vm._v("答题时间 " + _vm._s(_vm.time))
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.isResult
            ? _c(
                "van-row",
                { staticClass: "badges" },
                [
                  _c(
                    "van-col",
                    { staticClass: "badge success", attrs: { span: "8" } },
                    [_vm._v("答对" + _vm._s(_vm.rightCount) + "题")]
                  ),
                  _c(
                    "van-col",
                    { staticClass: "badge error", attrs: { span: "8" } },
                    [_vm._v("答错" + _vm._s(_vm.errorCount) + "题")]
                  ),
                  _c(
                    "van-col",
                    { staticClass: "badge", attrs: { span: "8" } },
                    [_vm._v("未做" + _vm._s(_vm.undoCount) + "题")]
                  )
                ],
                1
              )
            : _c(
                "van-row",
                { staticClass: "badges" },
                [
                  _c(
                    "van-col",
                    { staticClass: "badge done", attrs: { span: "8" } },
                    [_vm._v("已答题")]
                  ),
                  _c(
                    "van-col",
                    { staticClass: "badge", attrs: { span: "8" } },
                    [_vm._v("未答题")]
                  )
                ],
                1
              ),
          _c(
            "van-row",
            { staticClass: "answer-list", attrs: { type: "flex" } },
            _vm._l(
              _vm.isResult ? _vm.randomAnswerCard : _vm.orderAnswerCard,
              function(item, index) {
                return _c("van-col", { key: index, attrs: { span: "6" } }, [
                  _c(
                    "div",
                    {
                      class: [
                        "answer-item",
                        {
                          success: item.answerStatus === 1,
                          error: item.answerStatus === 2,
                          done: item.done
                        }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.select(index)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(index + 1) + " ")]
                  )
                ])
              }
            ),
            1
          ),
          _vm.isMini && _vm.type == "result"
            ? _c(
                "van-row",
                {
                  staticClass: "mini-result__options",
                  attrs: { type: "flex" }
                },
                [
                  _c(
                    "van-col",
                    { attrs: { span: "9" } },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "large",
                            round: "",
                            plain: ""
                          },
                          on: { click: _vm.again }
                        },
                        [_vm._v("再测一次")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-col",
                    { attrs: { span: "9", offset: "4" } },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: { type: "primary", size: "large", round: "" },
                          on: { click: _vm.viewFirstQuestion }
                        },
                        [_vm._v("查看解析")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$slots.bottom
            ? _c(
                "van-row",
                { staticClass: "bottom-operates" },
                [_vm._t("bottom")],
                2
              )
            : !_vm.isResult
            ? _c(
                "van-row",
                {
                  staticClass: "bottom-operates",
                  attrs: { gutter: "20", type: "flex" }
                },
                [
                  _c(
                    "van-col",
                    { attrs: { span: "9", offset: 2 } },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "large",
                            round: "",
                            plain: ""
                          },
                          on: { click: _vm.close }
                        },
                        [_vm._v("关闭答题卡")]
                      )
                    ],
                    1
                  ),
                  _vm.isRandom
                    ? _c(
                        "van-col",
                        { attrs: { offset: "2", span: "9" } },
                        [
                          _c(
                            "van-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "large",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.commitAnswer(_vm.doneCount)
                                }
                              }
                            },
                            [_vm._v("提交答题卡")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm.isMini && _vm.type == "result"
        ? _c(
            "van-row",
            {
              staticClass: "bottom-operates mini-result",
              attrs: { type: "flex", align: "center" },
              on: { click: _vm.goPlan }
            },
            [
              _c("img", {
                staticClass: "ad",
                attrs: { src: require("assets/imgs/ad.png"), alt: "" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }