"use strict";

var _interopRequireWildcard = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireWildcard").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var echarts = _interopRequireWildcard(require("echarts/core"));

var _charts = require("echarts/charts");

var _components = require("echarts/components");

var _renderers = require("echarts/renderers");

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
// 引入柱状图图表，图表后缀都为 Chart
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
// 注册必须的组件
echarts.use([_components.TitleComponent, _components.LegendComponent, _components.TooltipComponent, _components.GridComponent, _charts.RadarChart, _renderers.CanvasRenderer]);
var _default = echarts;
exports.default = _default;