"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Topic = _interopRequireDefault(require("./components/Topic"));

var _Choice = _interopRequireDefault(require("@/components/QuestionType/Choice"));

var _Fillin = _interopRequireDefault(require("@/components/QuestionType/Fillin"));

var _Grid = _interopRequireDefault(require("@/components/QuestionType/Grid"));

var _constant = require("@/utils/constant");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Question',
  components: {
    Topic: _Topic.default,
    Choice: _Choice.default,
    Fillin: _Fillin.default,
    Grid: _Grid.default
  },
  props: {
    question: {
      type: Object,
      require: true
    },
    solution: {
      type: [Boolean, Object],
      require: false
    },
    showErrorCount: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    questionType() {
      return _constant.questionComponents[this.question.subjectType];
    },

    article() {
      if (!this.question.articleId) {
        return false;
      }

      return this.$store.state.mini.articleList[this.question.articleId];
    }

  },
  methods: {
    // 查看文章
    viewArticle() {
      this.$store.commit('SET_VIEW_ARTICLE', {
        id: this.question.articleId
      });
    }

  }
};
exports.default = _default;