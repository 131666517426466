var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topic" }, [
    _c("div", { staticClass: "topic-content" }, [
      _vm.volume
        ? _c("img", {
            staticClass: "play-volume",
            attrs: { src: require("assets/imgs/icons/sound.png"), alt: "" },
            on: { click: _vm.play }
          })
        : _vm._e(),
      _c("span", [_vm._v(_vm._s(_vm.title))])
    ]),
    _vm.image
      ? _c("div", {
          staticClass: "topic-img",
          style: { backgroundImage: "url(" + _vm.image + ")" }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }