"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style/less");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("vant/es/tabs/style/less");

var _tabs = _interopRequireDefault(require("vant/es/tabs"));

require("vant/es/tab/style/less");

var _tab = _interopRequireDefault(require("vant/es/tab"));

var _subject = require("@/api/subject");

var _constant = require("@/utils/constant");

var _vue = _interopRequireDefault(require("vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.use(_tab.default);

_vue.default.use(_tabs.default);

var _default = {
  name: 'Exercise',

  data() {
    return {
      gradeList: [],
      // 等级列表
      nowGradeKey: 'nowGrade',
      // sessionStorage key
      nowGrade: 1,
      // 当前等级
      icons: _constant.questionTypes,
      // 图标映射
      questionTypeList: [] // 题目类型列表

    };
  },

  mounted() {
    this.getGradeList();
  },

  methods: {
    // 获取等级
    getGradeList() {
      (0, _subject.getSubjectLevel)().then(res => {
        this.gradeList = res.data;
        this.gradeList.map(item => {
          if (item.isCheck) {
            this.nowGrade = item.code;
          }
        });
        this.getQuestionTypeList();
      });
    },

    // 获取题型
    getQuestionTypeList() {
      (0, _subject.getSubjectType)({
        subjectLevel: this.nowGrade
      }).then(res => {
        this.questionTypeList = res.data;
      });
    },

    // 切换等级
    changeGrade(grade) {
      this.nowGrade = grade;
      this.getQuestionTypeList();
    },

    // 开始答题
    start(questionType) {
      if (this.nowGrade <= 0 || questionType <= 0) {
        (0, _toast.default)('数据有误，请重试');
        return;
      }

      this.$store.dispatch('startOrderExercise', {
        orderLevel: this.nowGrade,
        orderQuestionType: questionType
      }).then(() => {
        this.$router.push('/exercise');
      }).catch(error => {
        (0, _toast.default)(error);
      });
    }

  }
};
exports.default = _default;