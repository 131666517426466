var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "van-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c(
            "van-row",
            { staticClass: "field" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("学校名称")]),
              _c(
                "van-row",
                {
                  staticClass: "picker",
                  attrs: { type: "flex", justify: "between" },
                  on: {
                    click: function($event) {
                      return _vm.showPicker("school")
                    }
                  }
                },
                [
                  _c(
                    "van-col",
                    { staticClass: "value", attrs: { span: "20" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.user && _vm.user.schoolName
                            ? _vm.user.schoolName
                            : "请选择"
                        )
                      )
                    ]
                  ),
                  _c(
                    "van-col",
                    { staticClass: "arrow", attrs: { span: "4" } },
                    [_c("van-icon", { attrs: { name: "arrow" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "van-row",
            { staticClass: "field" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("年级")]),
              _c(
                "van-row",
                {
                  staticClass: "picker",
                  attrs: { type: "flex", justify: "between" },
                  on: {
                    click: function($event) {
                      return _vm.showPicker("grade")
                    }
                  }
                },
                [
                  _c(
                    "van-col",
                    { staticClass: "value", attrs: { span: "20" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.user && _vm.user.gradeName
                            ? _vm.user.gradeName
                            : "请选择"
                        )
                      )
                    ]
                  ),
                  _c(
                    "van-col",
                    { staticClass: "arrow", attrs: { span: "4" } },
                    [_c("van-icon", { attrs: { name: "arrow" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "van-row",
            { staticClass: "field" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("班级")]),
              _c(
                "van-row",
                {
                  staticClass: "picker",
                  attrs: { type: "flex", justify: "between" },
                  on: {
                    click: function($event) {
                      return _vm.showPicker("class")
                    }
                  }
                },
                [
                  _c(
                    "van-col",
                    { staticClass: "value", attrs: { span: "20" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.user && _vm.user.className
                            ? _vm.user.className
                            : "请选择"
                        )
                      )
                    ]
                  ),
                  _c(
                    "van-col",
                    { staticClass: "arrow", attrs: { span: "4" } },
                    [_c("van-icon", { attrs: { name: "arrow" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "van-row",
            { staticClass: "field" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("学生姓名")]),
              _c("van-field", {
                staticClass: "name-input",
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.user.studentName,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "studentName", $$v)
                  },
                  expression: "user.studentName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    round: "",
                    block: "",
                    type: "primary",
                    "native-type": "submit"
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          ),
          _vm._l(_vm.pickers, function(item, index) {
            return _c(
              "van-popup",
              {
                key: index,
                attrs: { position: "bottom" },
                model: {
                  value: _vm.showPickers[item],
                  callback: function($$v) {
                    _vm.$set(_vm.showPickers, item, $$v)
                  },
                  expression: "showPickers[item]"
                }
              },
              [
                _c("van-picker", {
                  attrs: {
                    "show-toolbar": "",
                    columns: _vm.list[item],
                    "confirm-button-text": "确定",
                    "value-key": "name"
                  },
                  on: {
                    confirm: _vm.onConfirm,
                    cancel: function($event) {
                      return _vm.onCancel(item)
                    }
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }