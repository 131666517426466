var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-container" },
    [
      _c(
        "van-row",
        { attrs: { type: "flex" } },
        _vm._l(_vm.navList, function(item, index) {
          return _c(
            "van-col",
            { key: index, staticClass: "item-wrapper", attrs: { span: 12 } },
            [_c("nav-item", { attrs: { info: item } })],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }