var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-container" },
    _vm._l(_vm.info, function(item, infoIndex) {
      return _c(
        "van-row",
        { key: infoIndex },
        [
          _c("p", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
          _vm._l(item.questions, function(question, questionIndex) {
            return _c("van-row", { key: questionIndex, staticClass: "item" }, [
              _c("p", { staticClass: "question" }, [
                _vm._v(_vm._s(question.title))
              ]),
              _c("p", { staticClass: "answer" }, [
                _vm._v(_vm._s(question.answer))
              ])
            ])
          })
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }