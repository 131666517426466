"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ShareDialog',
  computed: {
    display() {
      return this.$store.state.exercise.displayShare;
    }

  },
  methods: {
    close() {
      this.$store.commit('SET_DISPLAY_SHARE', false);
    }

  }
};
exports.default = _default;