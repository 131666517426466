"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Grid',
  props: {
    question: {
      type: Object,
      require: true
    },
    solution: {
      type: [Boolean, Object],
      require: false
    }
  },

  data() {
    return {
      nums: ['一', '二', '三', '四', '五'],
      span: 8,
      gridClass: 'grid-9',
      nowRow: 0,
      // 当前答案行
      nowCol: 0,
      // 当前答案列
      isFocus: false,
      // 是否对焦
      isActive: false,
      text: [],
      // 文字对应的答案行列
      answer: {} // 答案行列对应的文字索引

    };
  },

  computed: { ...(0, _vuex.mapState)({
      gridAnswer: function (state) {
        const gridAnswer = state.exercise.gridAnswers[this.question.poetrySubjectId];

        if (!gridAnswer) {
          this.nowRow = 0;
          this.nowCol = 0;
          return this.initAnswer();
        }

        return gridAnswer;
      }
    }),
    textIndexs: function () {
      return this.gridAnswer ? Object.values(this.gridAnswer) : [];
    },
    answers: function (state) {
      if (this.solution && this.solution.answerStatus > 0) {
        // 已做的题显示答案
        return this.solution.userAnswers.map(item => item.answer.split(''));
      }

      return this.gridAnswer ? this.gridAnswer : [];
    }
  },

  mounted() {
    // 计算布局
    this.calcGrid(); // 初始化答案

    this.initAnswer(); // 点击宫格input不失焦

    this.$refs.grid.addEventListener('mousedown', e => {
      e.preventDefault();
    });
  },

  methods: {
    // 初始化答案
    initAnswer() {
      const answer = {};

      for (let i = 0; i < this.question.answerCount; i++) {
        this.commitAnswer(i, '');

        for (let j = 0; j < this.question.answerLengths[i]; j++) {
          answer[i + '-' + j] = -1;
        }
      }

      this.$store.commit('SET_GRID_ANSWER', {
        id: this.question.poetrySubjectId,
        answer: answer
      });
      return answer;
    },

    calcGrid() {
      if (this.question.options.length === 20) {
        this.span = 5;
      }

      this.gridClass = `grid-${this.question.options.length}`;
    },

    // 输入框focus
    handleFocus(index) {
      this.inputFocusIndex = index;
      this.isFocus = true;
    },

    // 失焦
    handleBlur() {
      this.isFocus = false;
    },

    // 模拟:active效果
    handleTouchstart(index) {
      this.isActive = index;
    },

    handleTouchend() {
      setTimeout(() => {
        this.isActive = false;
      }, 100);
    },

    // 获取未填空的坐标
    getEmpty(row, col) {
      let i = row || 0;
      let j = col || 0;
      let reload = false;

      for (; i < this.question.answerCount;) {
        for (; j < this.question.answerLengths[i];) {
          if (i === row && j === col && reload) {
            return false;
          }

          if (this.gridAnswer[`${i}-${j}`] === -1) {
            return [i, j];
          }

          j++;

          if (j >= this.question.answerLengths[i]) {
            j = 0;

            if (i >= this.question.answerCount - 1) {
              i = 0;
              reload = true;
            } else {
              i++;
            }
          }
        }
      }

      return false;
    },

    formatAnswer(row) {
      const answerLength = this.question.answerLengths[row];
      const answer = [];

      for (let i = 0; i < answerLength; i++) {
        const key = `${row}-${i}`;
        answer.push(this.gridAnswer[key] > -1 ? this.question.options[this.gridAnswer[key]].subjectOption : ' ');
      }

      return answer.join('');
    },

    // 点击文字
    clickText(index) {
      if (this.solution) {
        return;
      }

      const actived = this.textIndexs.indexOf(index) > -1;
      let row = this.nowRow;
      let col = this.nowCol;

      if (actived) {
        // 已选中
        const position = this.getPosition(index);
        index = -1;
        row = parseInt(position[0]);
        col = parseInt(position[1]);
        this.nowRow = row;
        this.nowCol = col;
      } else if (this.nowRow === -1 || this.nowCol === -1) {
        return;
      }

      this.commitGridAnswer(row, col, index);
      this.commitAnswer(row, this.formatAnswer(row)); // 跳到下一个

      if (!actived) {
        const empty = this.getEmpty(row, col);

        if (empty) {
          this.nowRow = empty[0];
          this.nowCol = empty[1];
        } else {
          this.nowRow = -1;
          this.nowCol = -1;
        }
      }
    },

    // 点击答案
    clickAnswer(row, col) {
      if (this.solution) {
        return;
      }

      this.nowRow = row;
      this.nowCol = col;
      const key = `${row}-${col}`;

      if (this.gridAnswer[key] > -1) {
        this.commitGridAnswer(row, col, -1);
      }

      this.commitAnswer(row, this.formatAnswer(row));
    },

    getPosition(textIndex) {
      for (const p in this.gridAnswer) {
        if (this.gridAnswer[p] === textIndex) {
          return p.split('-');
        }
      }

      return false;
    },

    commitGridAnswer(row, col, index) {
      const list = this.$store.state.exercise.gridAnswers;
      const answer = list && list[this.question.poetrySubjectId] ? list[this.question.poetrySubjectId] : [];
      answer[`${row}-${col}`] = index;
      this.$store.commit('SET_GRID_ANSWER', {
        id: this.question.poetrySubjectId,
        answer: answer
      });
    },

    commitAnswer(index, val) {
      // 设置答案
      const list = this.$store.state.exercise.answerList;
      const answers = list && list[this.question.poetrySubjectId] ? list[this.question.poetrySubjectId] : [];
      answers[index] = val;
      this.$store.commit('SET_ANSWER', {
        poetrySubjectId: this.question.poetrySubjectId,
        answer: answers
      });

      if (!this.isFocus) {
        document.activeElement.blur();
      }
    }

  }
};
exports.default = _default;