"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = require("@/utils/constant");

var _subject = require("@/api/subject");

var _mini = require("@/api/mini");

const state = {
  /**
   * 练习类型
   * 对应constant.exerciseType
   */
  exerciseType: null,
  orderLevel: null,
  // 顺序练习的等级
  orderQuestionType: null,
  // 顺序练习的题目类型
  questionList: [],
  // 题目列表
  nowQuestion: null,
  // 当前题目
  answerList: {},
  // 答案列表
  solutionList: {},
  // 正确答案列表
  orderAnswerCard: null,
  // 顺序练习答题卡
  displayRandomResult: false,
  // 查看随机练习结果
  randomAnswerCard: null,
  // 随机练习答题卡
  viewErrorQuestion: false,
  // 只看错题
  randomResult: null,
  // 某次随机练习结果
  displayShare: false,
  // 分享提示弹窗
  volumePlayer: null,
  // 朗读播放器
  gridAnswers: [],
  // 宫格题目的答案
  isMini: true,
  // 是否是小程序答题
  isMiniResult: false,
  // 查看小程序答题结果
  miniPath: '',
  // 小程序路径
  miniLoginInfo: {},
  // 小程序答题信息
  articleList: {},
  // 阅读理解题文章列表
  nowArticle: 0,
  // 当前显示文章id
  miniResult: {} // 小程序答题结果，包括分数是否通过等

}; // 格式化请求的答案

const formatAnswersList = (question, answerList) => {
  const notAnswer = {
    answer: '',
    sort: 0
  }; // 默认空答案

  let answers = [];
  const {
    poetrySubjectId,
    options,
    subjectType
  } = question;

  if (!answerList[poetrySubjectId]) {
    return answers;
  }

  if (_constant.questionComponents[subjectType] === 'choice') {
    // 选择题
    answers = answerList[poetrySubjectId].map((item, index) => {
      return {
        answer: options[item].subjectOption,
        answerContent: options[item].optionName,
        sort: index + 1
      };
    });
  } else if (_constant.questionComponents[subjectType] === 'grid' && answerList[poetrySubjectId].join('') === '') {
    // 宫格题未填写答案特殊处理
    return answers;
  } else {
    // 非选择题（填空题)
    // 答案可能是undefined
    const answerListTmp = [];

    for (let i = 0; i < answerList[poetrySubjectId].length; i++) {
      if (!answerList[poetrySubjectId][i]) {
        answerListTmp.push(notAnswer);
      } else {
        answerListTmp.push({
          answer: answerList[poetrySubjectId][i],
          answerContent: answerList[poetrySubjectId][i],
          sort: i + 1
        });
      }
    }

    answers = answerListTmp;
  }

  return answers;
};

const mutations = {
  // 练习模式
  SET_EXERCISE_TYPE(state, type) {
    state.exerciseType = type;
  },

  // 顺序答题等级和题型
  SET_EXERCISE_ORDER(state, payload) {
    state.orderLevel = payload.orderLevel;
    state.orderQuestionType = payload.orderQuestionType;
  },

  // 题目列表
  SET_QUESTION_LIST(state, list) {
    state.questionList = list;
  },

  // 当前题目
  SET_NOW_QUESTION(state, question) {
    state.nowQuestion = question;
  },

  // 设置当前题目作答
  SET_ANSWER(state, payload) {
    const newObj = {};
    newObj[payload.poetrySubjectId] = payload.answer;
    const obj = Object.assign({}, state.answerList, newObj);
    state.answerList = obj;
  },

  // 设置用户答案列表
  SET_ANSWER_LIST(state, list) {
    state.answerList = list;
  },

  // 设置当前题目正确答案
  SET_SOLUTION(state, payload) {
    const newObj = {};
    newObj[payload.poetrySubjectId] = payload.data;
    const obj = Object.assign({}, state.solutionList, newObj);
    state.solutionList = obj;
  },

  // 设置解析列表
  SET_SOLUTION_LIST(state, list) {
    state.solutionList = list;
  },

  // 清空答案和解析
  CLEAR_ANSWER(state, payload) {
    state.questionList = [];
    state.answerList = {};
    state.solutionList = {};
    state.orderAnswerCard = null;
    state.displayRandomResult = false;
    state.randomAnswerCard = null;
    state.viewErrorQuestion = false;
    state.randomResult = false;
    state.displayShare = false;
  },

  // 设置顺序练习答题卡
  SET_ORDER_ANSWER_CARD(state, list) {
    state.orderAnswerCard = list;
  },

  // 设置随机练习答题卡
  SET_RANDOM_ANSWER_CARD(state, list) {
    state.randomAnswerCard = list;
  },

  // 设置显示随机练习结果
  SET_DISPLAY_RANDOM_RESULT(state, display) {
    state.displayRandomResult = display;
  },

  // 设置是否只看错题
  SET_VIEW_ERROR_QUESTION(state, status) {
    state.viewErrorQuestion = status;
  },

  // 设置随机练习结果
  SET_RANDOM_RESULT(state, data) {
    state.randomResult = data;
  },

  // 设置分享提示弹窗
  SET_DISPLAY_SHARE(state, status) {
    state.displayShare = status;
  },

  // 设置朗读播放器
  SET_VOLUME_PLAYER(state, player) {
    state.volumePlayer = player;
  },

  // 设置宫格题目答案
  SET_GRID_ANSWER(state, payload) {
    const newObj = {};
    newObj[payload.id] = payload.answer;
    const obj = Object.assign({}, state.gridAnswers, newObj);
    state.gridAnswers = obj;
  },

  SET_VALUE(state, payload) {
    state[payload['key']] = payload['value'];
  },

  // 查看文章
  SET_VIEW_ARTICLE(state, payload) {
    state.nowArticle = payload.id;
  }

};
const actions = {
  // 开始顺序练习
  startOrderExercise({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_EXERCISE_TYPE', _constant.exerciseTypes.order);
      commit('SET_EXERCISE_ORDER', payload);
      (0, _subject.getOrderQuestionList)({
        subjectLevel: payload.orderLevel,
        subjectType: payload.orderQuestionType
      }).then(res => {
        if (res.code === 200) {
          if (res.data.length <= 0) {
            // 获取不到题目
            return reject('题库空空的，等待补充');
          }

          commit('SET_QUESTION_LIST', res.data);
          resolve();
        } else {
          reject(res.message);
        }
      });
    });
  },

  // 开始随机练习
  startRandomExericse({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_EXERCISE_TYPE', _constant.exerciseTypes.random);
      (0, _subject.getRandomQuestionList)().then(res => {
        if (res.code === 200) {
          if (res.data.length <= 0) {
            // 获取不到题目
            return reject(res);
          }

          commit('SET_QUESTION_LIST', res.data);
          resolve();
        } else {
          reject(res);
        }
      });
    });
  },

  // 开始错题集练习
  startErrorExericse({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_EXERCISE_TYPE', _constant.exerciseTypes.error);
      (0, _subject.getErrorQuestionList)().then(res => {
        if (res.code === 200) {
          commit('SET_QUESTION_LIST', res.data);
          resolve();
        } else {
          reject(res.message);
        }
      });
    });
  },

  // 开始收藏集练习
  startCollectExericse({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_EXERCISE_TYPE', _constant.exerciseTypes.collect);
      (0, _subject.getCollectQuestionList)().then(res => {
        if (res.code === 200) {
          commit('SET_QUESTION_LIST', res.data);
          resolve();
        } else {
          reject(res.message);
        }
      });
    });
  },

  // 开始小程序答题
  startMiniExericse({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_EXERCISE_TYPE', _constant.exerciseTypes.random); // 获取试卷id

      (0, _mini.login)({
        code: payload.code
      }).then(res => {
        if (res.code !== 200) {
          return reject(res);
        }

        commit('SET_VALUE', {
          key: 'miniLoginInfo',
          value: res.data
        });
        sessionStorage.setItem('token', res.data.tokenId); // 获取题目

        (0, _mini.getQuestionList)({
          paperId: res.data.paperId
        }).then(res => {
          if (res.code === 200) {
            if (res.data.length <= 0) {
              // 获取不到题目
              return reject(res);
            } // 优化字段不同的问题


            const questionList = [];
            res.data.subjectList.map(item => {
              const question = item;
              question.poetrySubjectId = item.subjectId;
              questionList.push(question);
            });
            commit('SET_QUESTION_LIST', questionList);

            if (res.data.articleList) {
              const articleList = {};
              res.data.articleList.map(item => {
                articleList[item['articleId']] = item;
              });
              commit('SET_VALUE', {
                key: 'articleList',
                value: articleList
              });
            }

            resolve(res.data);
          } else {
            reject(res);
          }
        });
      }).catch(res => {
        return reject(res);
      });
    });
  },

  // 查看小程序答题结果
  viewMiniResult({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_EXERCISE_TYPE', _constant.exerciseTypes.random); // 获取试卷id

      (0, _mini.login)({
        code: payload.code
      }).then(res => {
        if (res.code !== 200) {
          return reject(res);
        }

        commit('SET_VALUE', {
          key: 'miniLoginInfo',
          value: res.data
        });
        commit('SET_VALUE', {
          key: 'isMiniResult',
          value: true
        });
        sessionStorage.setItem('token', res.data.tokenId); // 获取答题结果

        (0, _mini.getExamResult)({
          paperId: res.data.paperId
        }).then(res => {
          if (res.code === 200) {
            if (res.data.subjectList.length <= 0) {
              // 获取不到题目
              return reject(res);
            } // 优化字段不同的问题


            const questionList = [];
            res.data.subjectList.map(item => {
              const question = item;
              question.poetrySubjectId = item.subjectId;
              questionList.push(question);
            });
            commit('SET_QUESTION_LIST', questionList);

            if (res.data.articleList) {
              const articleList = {};
              res.data.articleList.map(item => {
                articleList[item['articleId']] = item;
              });
              commit('SET_VALUE', {
                key: 'articleList',
                value: articleList
              });
            } // 结果答题卡


            commit('SET_RANDOM_ANSWER_CARD', res.data.subjectList); // 答题结果

            commit('SET_VALUE', {
              key: 'miniResult',
              value: {
                examScore: res.data.examScore,
                isPass: res.data.isPass
              }
            }); // 显示结果页面

            commit('SET_DISPLAY_RANDOM_RESULT', true); // 设置解析

            const solutionList = Object.assign({});
            res.data.subjectList.map(item => {
              solutionList[item.subjectId] = item;
            });
            commit('SET_SOLUTION_LIST', solutionList); // 显示结果页面

            commit('SET_DISPLAY_RANDOM_RESULT', true);
            resolve(res.data);
          } else {
            return reject('获取失败');
          }
        });
      }).catch(err => {
        return reject(err);
      });
    });
  },

  // 设置当前题目收藏状态
  setCollectStatus({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      let question = Object.assign({}, state.nowQuestion);

      if (!question) {
        return reject('数据异常');
      }

      const data = {
        poetrySubjectId: question.poetrySubjectId
      };
      let res = null;
      const status = !question.isCollect;

      if (status) {
        res = (0, _subject.addCollect)(data);
      } else {
        res = (0, _subject.removeCollect)(data);
      }

      res.then(resp => {
        if (resp.code !== 200) {
          return reject(resp.message);
        }

        const questionList = state.questionList.slice();
        question.isCollect = status;
        questionList[state.nowQuestion.questionIndex].isCollect = status;

        if (state.exerciseType === _constant.exerciseTypes.collect) {
          // 移除解答
          const solutionList = state.solutionList;

          if (solutionList[question.poetrySubjectId]) {
            solutionList[question.poetrySubjectId] = undefined;
            commit('SET_SOLUTION_LIST', solutionList);
          } // 取消收藏需要设置当前题目


          const questionLen = questionList.length;
          questionList.splice(state.nowQuestion.questionIndex, 1);

          if (questionList.length > 0) {
            if (questionList.length === 1) {
              question = questionList[0];
              question.questionIndex = 0;
            } else {
              const index = state.nowQuestion.questionIndex === 0 ? 0 : state.nowQuestion.questionIndex === questionLen - 1 ? questionList.length - 1 : state.nowQuestion.questionIndex;
              question = questionList[index];
              question.questionIndex = index;
              question.isCollect = true;
            }
          } else {
            question = null;
          }
        }

        commit('SET_NOW_QUESTION', question);
        commit('SET_QUESTION_LIST', questionList);
        resolve(status ? '收藏成功' : '取消成功');
      });
    });
  },

  // 提交单个答案
  setAnswer({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      if (!state.nowQuestion) {
        console.log(state.nowQuestion);
        return reject('数据异常');
      }

      const {
        recordType,
        recordKeyId,
        poetrySubjectId
      } = state.nowQuestion;
      const answers = formatAnswersList(state.nowQuestion, state.answerList);
      const data = {
        recordType,
        recordKeyId,
        poetrySubjectId,
        answers
      };
      (0, _subject.commitAnswer)(data).then(res => {
        if (res.code !== 200) {
          return reject(res);
        } // 修改解析


        commit('SET_SOLUTION', {
          poetrySubjectId: res.data.poetrySubjectId,
          data: res.data
        });

        if (state.exerciseType === _constant.exerciseTypes.order) {
          const answerCard = state.orderAnswerCard.slice(0); // 修改答题卡

          if (answerCard[state.nowQuestion.questionIndex]) {
            answerCard[state.nowQuestion.questionIndex].answerStatus = res.data.answerStatus;
            answerCard[state.nowQuestion.questionIndex].userAnswers = res.data.userAnswers;
          } else {
            answerCard[state.nowQuestion.questionIndex] = {
              answerStatus: res.data.answerStatus,
              answers: [],
              poetrySubjectId: res.data.poetrySubjectId,
              subjectExplam: res.data.subjectExplam
            };
          }

          commit('SET_ORDER_ANSWER_CARD', answerCard);
        }

        resolve(res.data);
      });
    });
  },

  // 获取顺序练习答题卡
  getOrderAnswerCard({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      (0, _subject.getOrderAnswerCard)(payload).then(res => {
        if (res.code !== 200) {
          return reject(res.message);
        }

        commit('SET_ORDER_ANSWER_CARD', res.data);
        resolve(res.data);
      });
    });
  },

  // 移除错题
  removeErrorQuestion({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      (0, _subject.removeErrorQuestion)({
        poetrySubjectId: payload.id
      }).then(res => {
        if (res.code !== 200) {
          return reject(res.message);
        }

        const questionList = state.questionList;
        questionList.splice(payload.questionIndex, 1);
        commit('SET_QUESTION_LIST', questionList);
        let nowQuestion = null;

        if (questionList.length > 0) {
          if (questionList.length === 1) {
            nowQuestion = questionList[0];
            nowQuestion.questionIndex = 0;
          } else {
            nowQuestion = questionList[payload.questionIndex];
            nowQuestion.questionIndex = state.nowQuestion.questionIndex;
          }
        }

        commit('SET_NOW_QUESTION', nowQuestion); // 移除解答

        const solutionList = state.solutionList;

        if (solutionList[payload.id]) {
          solutionList[payload.id] = undefined;
          commit('SET_SOLUTION_LIST', solutionList);
        }

        resolve();
      });
    });
  },

  // 提交随机练习答题卡
  commitRandomAnswerCard({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const infos = [];
      state.questionList.map(question => {
        const answers = formatAnswersList(question, state.answerList);
        infos.push({
          answers,
          poetrySubjectId: question.poetrySubjectId,
          recordKeyId: question.recordKeyId
        });
      });
      const data = {
        recordType: _constant.exerciseTypes.random,
        respondInfos: infos,
        elapsedTime: payload.time
      };
      (0, _subject.commitRandomAnswer)(data).then(res => {
        if (res.code !== 200) {
          return reject(res.message);
        } // 结果答题卡


        commit('SET_RANDOM_ANSWER_CARD', res.data); // 显示结果页面

        commit('SET_DISPLAY_RANDOM_RESULT', true); // 设置解析

        const solutionList = Object.assign({});
        res.data.map(item => {
          solutionList[item.poetrySubjectId] = item;
        });
        commit('SET_SOLUTION_LIST', solutionList);
        resolve(res.data);
      });
    });
  },

  // 提交小程序练习答题卡
  commitMiniAnswerCard({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      const infos = [];
      state.questionList.map(question => {
        const answers = formatAnswersList(question, state.answerList);
        infos.push({
          userAnswers: answers,
          subjectId: question.poetrySubjectId
        });
      });
      const data = {
        paperId: state.miniLoginInfo.paperId,
        subjectList: infos,
        answerElapsed: payload.time,
        planId: payload.planId
      };
      (0, _mini.commitMiniAnswer)(data).then(res => {
        if (res.code !== 200) {
          return reject(res.message);
        } // 结果答题卡


        commit('SET_RANDOM_ANSWER_CARD', res.data.subjectList); // 答题结果

        commit('SET_VALUE', {
          key: 'miniResult',
          value: {
            examScore: res.data.examScore,
            isPass: res.data.isPass
          }
        }); // 显示结果页面

        commit('SET_DISPLAY_RANDOM_RESULT', true); // 设置解析

        const solutionList = Object.assign({});
        res.data.subjectList.map(item => {
          solutionList[item.subjectId] = item;
        });
        commit('SET_SOLUTION_LIST', solutionList);
        resolve(res.data);
      });
    });
  },

  // 获取最近一次的随机答题结果
  getRandomResultLatest({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      (0, _subject.getRandomResultLatest)().then(res => {
        if (res.code !== 200) {
          return reject(res.message);
        }

        if (!res.data) {
          return resolve(false);
        }

        const questionList = [];
        const solutionList = [];
        const answerList = {};
        res.data.results.map(item => {
          questionList.push(item.subjectInfo);
          answerList[item.poetrySubjectId] = item.userAnswers.map(answerItem => {
            if (_constant.questionComponents[item.subjectInfo.subjectType] === 'choice') {
              // 选择题答案转为用从0开始数字表示
              return answerItem.answer.charCodeAt() - 65;
            }

            return answerItem.answer;
          });
          solutionList[item.poetrySubjectId] = item;
        }); // 练习模式

        commit('SET_EXERCISE_TYPE', _constant.exerciseTypes.random); // 随机练习结果

        commit('SET_RANDOM_RESULT', res.data); // 结果答题卡

        commit('SET_RANDOM_ANSWER_CARD', res.data.results); // 显示结果页面

        commit('SET_DISPLAY_RANDOM_RESULT', true); // 题目

        commit('SET_QUESTION_LIST', questionList); // 解析

        commit('SET_SOLUTION_LIST', solutionList); // 用户答案

        commit('SET_ANSWER_LIST', answerList);
        resolve(res.data);
      });
    });
  }

};
var _default = {
  state,
  mutations,
  actions
};
exports.default = _default;