"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constant = require("@/utils/constant");

var _mini = require("@/api/mini");

const state = {
  paperId: 1,
  // 检测试卷id
  questionList: [{
    id: 1,
    stem: '题目标题',
    options: [],
    subjectType: 1
  }],
  // 题目列表
  articleList: {},
  // 阅读理解题文章列表
  nowQuestion: null,
  // 当前题目
  answerList: {},
  // 答案列表
  solutionList: {},
  // 正确答案列表
  orderAnswerCard: null,
  // 顺序练习答题卡
  displayResult: false,
  // 查看随机练习结果
  answerCard: null,
  // 随机练习答题卡
  viewErrorQuestion: false,
  // 只看错题
  volumePlayer: null,
  // 朗读播放器
  gridAnswers: [],
  // 宫格题目的答案
  nowArticle: 0 // 当前显示文章id

};
const mutations = {
  SET_VALUE(state, payload) {
    state[payload['key']] = payload['value'];
  },

  // 查看文章
  SET_VIEW_ARTICLE(state, payload) {
    state.nowArticle = payload.id;
  },

  // 设置当前题目作答
  SET_ANSWER(state, payload) {
    const newObj = {};
    newObj[payload.poetrySubjectId] = payload.answer;
    const obj = Object.assign({}, state.answerList, newObj);
    state.answerList = obj;
  }

}; // 格式化请求的答案

const formatAnswersList = (question, answerList) => {
  const notAnswer = {
    answer: '',
    sort: 0
  }; // 默认空答案

  let answers = [notAnswer];
  const {
    poetrySubjectId,
    options,
    subjectType
  } = question;

  if (!answerList[poetrySubjectId]) {
    return answers;
  }

  if (_constant.questionComponents[subjectType] === 'choice') {
    // 选择题
    answers = answerList[poetrySubjectId].map((item, index) => {
      return {
        answer: options[item].subjectOption,
        answerContent: options[item].optionContent,
        sort: index + 1
      };
    });
  } else {
    // 非选择题（填空题)
    // 答案可能是undefined
    const answerListTmp = [];

    for (let i = 0; i < answerList[poetrySubjectId].length; i++) {
      if (!answerList[poetrySubjectId][i]) {
        answerListTmp.push(notAnswer);
      } else {
        answerListTmp.push({
          answer: answerList[poetrySubjectId][i],
          sort: i + 1
        });
      }
    }

    answers = answerListTmp;
  }

  return answers;
};

const actions = {
  // 鉴权
  auth({
    commit
  }, payload) {},

  // 获取题目
  getQuestionList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      (0, _mini.getQuestionList)({
        paperId: state.paperId
      }).then(res => {
        if (res.code === 200) {
          if (res.data.length <= 0) {
            // 获取不到题目
            return reject('获取题目失败');
          }

          commit('SET_VALUE', {
            key: 'questionList',
            value: res.data.subjectList
          });

          if (res.data.articleList) {
            const articleList = {};
            res.data.articleList.map(item => {
              articleList[item['articleId']] = item;
            });
            commit('SET_VALUE', {
              key: 'articleList',
              value: articleList
            });
          }

          resolve();
        } else {
          reject(res.message);
        }
      });
    });
  }

};
var _default = {
  state,
  mutations,
  actions
};
exports.default = _default;