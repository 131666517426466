var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty-container" }, [
    _c("img", { attrs: { src: require("assets/imgs/empty.svg"), alt: "" } }),
    _c("p", [_vm._t("default", [_vm._v("暂无内容哦~")])], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }