"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Subscribe',

  data() {
    return {
      qrcode: require('assets/imgs/subscribe_' + (process.env.VUE_APP_ENV === 'production' ? 'qrcode' : 'guanshu') + '.png')
    };
  }

};
exports.default = _default;