var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.question.options, function(item, index) {
      return _c(
        "van-button",
        {
          key: item.optionId,
          staticClass: "option-btn",
          class: {
            answer:
              _vm.parseSolution && _vm.parseSolution.answer.indexOf(index) > -1
          },
          attrs: {
            plain: "",
            block: "",
            size: "large",
            status: _vm.optType(index)
          },
          on: {
            click: function($event) {
              return _vm.select(index)
            }
          }
        },
        [
          _c(
            "van-row",
            { staticClass: "question-option", attrs: { type: "flex" } },
            [
              _c("van-col", { staticClass: "option" }, [
                _vm._v(_vm._s(item.subjectOption))
              ]),
              _c("van-col", { staticClass: "content" }, [
                _vm._v(_vm._s(item.optionContent))
              ])
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }