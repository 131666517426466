"use strict";

var _interopRequireDefault = require("/data/lib/jenkins/workspace/readingcloud-wx-applets/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

var _router = _interopRequireDefault(require("./router"));

_router.default.beforeEach((to, from, next) => {
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  console.log(to);
  next(); // 不需要登录的页面跳过权限判断
  // if (to.meta.auth === false) {
  //   next()
  // }
  // 开发环境模拟登录
  // if (process.env.VUE_APP_ENV === 'development') {
  //   store.commit('SET_TOKEN', 'dev_tem_oJlwe1AMNo5GZVG14Ox7vQ7yfy3g_aldsufab')
  // }
  // 登录状态
  // const token = store.getters.token
  // if (token) {
  //   next()
  //   return
  // }
  // const url = window.location.href
  // const parseUrl = qs.parse(url.split('?')[1])
  // const isAuth = store.state.user.authStatus
  // if (isAuth) {
  //   next()
  //   return
  // }
  // if (parseUrl.code && parseUrl.state) {
  //   store.dispatch('auth', {
  //     code: parseUrl.code
  //   }).then(() => {
  //     next()
  //   }).catch((error) => {
  //     console.log('获取openid失败：', error)
  //   })
  // } else {
  //   // 非开发环境必须微信打开
  //   if (!isWeixin()) {
  //     if (to.name !== 'WechatEnv') {
  //       next('/wechatEnv')
  //     } else {
  //       next(false)
  //     }
  //     return
  //   }
  //   auth(url)
  //   next(false)
  // }
});